import { createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../../api/kyc-backend/api";
import { AsyncThunkActionType } from "../../store";
import { AsyncThunkDataType } from "../../common";
import { UboResponse } from "../slices/ubosSlice";
import { Components, Paths } from "../../../api/kyc-backend/generated/client";

type UpdateUboRequest = Components.Schemas.UpdateBeneficialOwner;
type PathParameters = Paths.UpdateBeneficialOwner.PathParameters;

export const uboBackUpdate: AsyncThunkActionType<
  UboResponse,
  AsyncThunkDataType<PathParameters, UpdateUboRequest, "data">
> = createAsyncThunk(
  "uboBackUpdate",
  async ({ params, data, config }, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      let elem = [
        {personalDetails: data}
      ]
      const response = await api.UpdateBeneficialOwner(params, data, config);
      return response.data as UboResponse;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);
