import { createSlice } from "@reduxjs/toolkit";
import { CaseReducerType } from "../../store";
import { Components } from '../../../api/kyc/generated/client';

type ReviewStatusType = Components.Schemas.ReviewStatusType;
type FormType = string;
type FormUpdatedType = {
    updated: boolean,
    form: FormType[],
    formName?: string,
    action?: any
}
type EditedConfirmType = {
	onConfirm?: any,
	onConfirmLink?: string,
	id?: string,
  general?: boolean
}
type EditedFormType = {
    values?: any,
    type?: string
}
type NavType = {
    next?: string,
    step?: number,
    substep?: number,
    refId?: string,
    nextName?: string
    nextButton?: string
    prev?: string
    prevName?: string,
    status?: ReviewStatusType,
    hideNav?: boolean,
    navPage?: string,
    navSubpage?: string,
    paginationPage?: string | number,
    sorters?: any,
    filters?: any,
    link?: string
}

type State = {
  nav: NavType,
  formUpdated: FormUpdatedType,
  currentEditedForm: EditedFormType,
  editedConfirm: EditedConfirmType
  
};
//type State = {}

const initialState: State = {
  nav: {
    next: '',
    step: 0,
  },
  formUpdated: {
    updated: false,
    form: [],
    action: undefined,
    formName: undefined
  },
  currentEditedForm: {
	type: '',
	values: {}
  },
  editedConfirm: {}
};

type CaseReducers<State> = {
  setNav: CaseReducerType<State, NavType>;
  setFormUpdated: CaseReducerType<State, FormUpdatedType>;
  setCurrentEditedForm: CaseReducerType<State, EditedFormType>;
  setEditedFormConfirmed: CaseReducerType<State, EditedConfirmType>;
};

export const navSlice = createSlice<State, CaseReducers<State>>({
  name: "navEl",
  initialState,
  reducers: {
    setNav: (state, { payload }) => {
      state.nav = payload;
    },
    setFormUpdated: (state, { payload }) => {
      state.formUpdated.updated = payload.updated  
      state.formUpdated.formName =  payload.formName
      state.formUpdated.action = payload.action

      if(payload.updated === true) {
        state.formUpdated.form.push(...payload.form)
      } else {
        state.formUpdated.form = []
      }
      
    },
	setEditedFormConfirmed: (state, { payload }) => {
        state.editedConfirm = payload
     
    },
	setCurrentEditedForm: (state, { payload }) => {
        state.currentEditedForm = payload
     
    }
    /*setCurrentEditedForm: (state, { payload }) => {
        let foundEl = state.currentEditedForm.findIndex(el => el.id === payload.id)
        if(foundEl > -1) {
            state.currentEditedForm[foundEl] = payload;
        } else {
            state.currentEditedForm.push(payload);
        }
     
    }*/
  }
});

export const { setNav, setFormUpdated, setCurrentEditedForm, setEditedFormConfirmed } = navSlice.actions;

export default navSlice.reducer;
