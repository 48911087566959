import { createSlice } from "@reduxjs/toolkit";
import { Components, Paths } from "../../../api/kyc/generated/client";
import { statusUpdate } from "../asyncThunks/statusUpdate";
import { individualStatusUpdate } from "../asyncThunks/individualStatusUpdate";
import { individualValidate } from "../asyncThunks/individualValidate";
import { individualDeclarationsValidate } from "../asyncThunks/individualDeclarationsValidate";
import { companyValidate } from "../asyncThunks/companyValidate";
import { authorizedPersonsValidate } from "../asyncThunks/authorizedPersonsValidate";
import { readyForReviewValidate } from "../asyncThunks/readyForReviewValidate";
import { individualReadyForReviewValidate } from "../asyncThunks/individualReadyForReviewValidate";
import { ubosValidate } from "../asyncThunks/ubosValidate";
import { IndividualSteps, CompanySteps } from 'stepsScheme'
import { ObjectKeyType } from "types";
import { CaseReducerType } from "../../store";
import { ValidationErrors } from "modules/kyc-backend/ui-elements/ValidationErrors";

export type StatusResponse = Components.Schemas.CompanyPreReviewValidated;
export type IndividualStatusResponse = Components.Schemas.IndividualPreReviewValidated;
export type IndividualDeclarationsValidateResponse = Components.Schemas.ValidatedModelEntryIndividualId;
export type IndividualValidateResponse = Components.Schemas.ValidatedModelEntryIndividualId
export type ProfileResponse = Components.Schemas.ValidatedModelEntryCompanyId;
export type AuthorizedPersonsResponse = Components.Schemas.ValidatedModelEntryAuthorizedPersonId[];
export type UbosResponse = Components.Schemas.ValidatedModelEntryBeneficialOwnerId[];
export type ReadyForReviewResponse = Components.Schemas.CompanyPreReviewValidated;
export type IndividualReadyForReviewResponse = Components.Schemas.IndividualPreReviewValidated;

type State = {
  updatedStatus: StatusResponse,
  companyValidated: ProfileResponse,
  individualValidated: IndividualValidateResponse;
  individualDeclarationsValidated: IndividualDeclarationsValidateResponse;
  individualUpdatedStatus: IndividualStatusResponse,
  authorizedPersonsValidated: AuthorizedPersonsResponse,
  beneficialOwnersValidated: UbosResponse,
  readyForReviewValidated: ReadyForReviewResponse,
  individualReadyForReviewValidated: StepErrors[]
};

interface ValidationError {
    message: string;
    ref: string;
    slug: string;
}

interface StepErrors {
	step: number;
  	validated: boolean;
	validationErrors: ValidationError[];
}

const initialState: State = {
  updatedStatus: {
    companyProfileValidated: {
      entry: '',
      validationErrors: []
    },
    authorizedPersonsValidated: [],
    beneficialOwnersValidated: [],
    numberOfDirectorsValidated: {
      entry: 0
    },
    numberOfSignatoryValidated: {
      entry: 0
    },
    numberOfBeneficialOwnersValidated: {
      entry: 0
    },
    percentageFilled: 0
  },
  companyValidated: {
    entry: '',
    validationErrors: []
  },
  individualValidated: {
    entry: '',
    validationErrors: []
  },
  individualDeclarationsValidated: {
    entry: '',
    validationErrors: []
  },
  individualUpdatedStatus: {
    // @ts-ignore
    beneficialOwnerValidated: {},
    // @ts-ignore
    declarationValidated: {}
  },
  authorizedPersonsValidated : [],
  beneficialOwnersValidated: [],
  readyForReviewValidated: {
    // @ts-ignore
    companyProfileValidated: {
      entry: '',
      validationErrors: []
    },
    numberOfDirectorsValidated: {
      entry: 0
    },
    numberOfSignatoryValidated: {
      entry: 0
    },
    numberOfBeneficialOwnersValidated: {
      entry: 0
    },
    percentageFilled: 0
  },
  individualReadyForReviewValidated : []
};

type CaseReducers<State> = {
  statusUpdateClear: CaseReducerType<State, object>;
  companyValidateClear: CaseReducerType<State, object>;
  individualValidateClear: CaseReducerType<State, object>;
  individualDeclarationsValidateClear: CaseReducerType<State, object>;
  authorizedPersonsValidateClear: CaseReducerType<State, object>;
  ubosValidateClear: CaseReducerType<State, object>;
  individualStatusUpdateClear: CaseReducerType<State, object>;
  readyForReviewValidateClear: CaseReducerType<State, object>;
  individualReadyForReviewValidateClear: CaseReducerType<State, object>;
};

export const statusSlice = createSlice<State, CaseReducers<State>>({
  name: "status",
  initialState,
  reducers: {
    statusUpdateClear: (state, { payload }) => {
      state.updatedStatus = initialState.updatedStatus
    },
    companyValidateClear: (state, { payload }) => {
      state.companyValidated = initialState.companyValidated
    },
    individualValidateClear: (state, { payload }) => {
      state.individualValidated = initialState.individualValidated
    },
    individualDeclarationsValidateClear: (state, { payload }) => {
      state.individualDeclarationsValidated = initialState.individualDeclarationsValidated
    },
    authorizedPersonsValidateClear: (state, { payload }) => {
      state.authorizedPersonsValidated = initialState.authorizedPersonsValidated
    },
    ubosValidateClear: (state, { payload }) => {
      state.beneficialOwnersValidated = initialState.beneficialOwnersValidated
    },
    individualStatusUpdateClear: (state, { payload }) => {
      state.individualUpdatedStatus = initialState.individualUpdatedStatus
    },
    readyForReviewValidateClear: (state, { payload }) => {
      state.readyForReviewValidated = initialState.readyForReviewValidated
    },
    individualReadyForReviewValidateClear: (state, { payload }) => {
        state.individualReadyForReviewValidated = initialState.individualReadyForReviewValidated
    }
  },
  extraReducers: (builder) => {
    builder.addCase(statusUpdate.fulfilled, (state, action) => {
      state.updatedStatus = action.payload
    });
    builder.addCase(companyValidate.fulfilled, (state, action) => {
      state.companyValidated = action.payload
    });
    builder.addCase(individualValidate.fulfilled, (state, action) => {
      state.individualValidated = action.payload
    });
    builder.addCase(individualDeclarationsValidate.fulfilled, (state, action) => {
      state.individualDeclarationsValidated = action.payload
    });
    builder.addCase(individualStatusUpdate.fulfilled, (state, action) => {
      state.individualUpdatedStatus = action.payload
    });
    builder.addCase(authorizedPersonsValidate.fulfilled, (state, action) => {
      state.authorizedPersonsValidated = action.payload
    });
    builder.addCase(ubosValidate.fulfilled, (state,action) => {
      state.beneficialOwnersValidated = action.payload
    });
    builder.addCase(readyForReviewValidate.fulfilled, (state,action) => {
      state.readyForReviewValidated = action.payload
    });
  },
});

export const { statusUpdateClear, companyValidateClear, individualValidateClear, individualDeclarationsValidateClear, authorizedPersonsValidateClear, ubosValidateClear, individualStatusUpdateClear, readyForReviewValidateClear, individualReadyForReviewValidateClear } = statusSlice.actions;
export default statusSlice.reducer;
