import Title from 'antd/es/typography/Title'
import * as Yup from 'yup'
import { moment } from '../../../kyc-front/ui-elements/FormDate'
import Text, { TextProps } from 'antd/es/typography/Text'
import { useNavigate, useParams, Link } from 'react-router-dom'
import { Space, Row, Col, Spin, Statistic, Timeline, Divider, message, Popconfirm, Table, Select, Drawer, Card } from 'antd'
import { ExclamationCircleOutlined, CloseCircleOutlined, CheckCircleOutlined, QuestionCircleOutlined, CaretLeftOutlined } from '@ant-design/icons'
import { store, dispatch } from '../../../../store/store'
import { useSelector } from '../../../../hooks/useSelector'
import React, { FC, useEffect, useState, useRef, forwardRef } from 'react'
import { useActionStatus } from '../../../../hooks/useActionStatus'
import { companyProfileBackGet } from '../../../../store/kyc-backend/asyncThunks/companyProfileBackGet'
import { companyBusinessProfileFromKycGet } from '../../../../store/kyc-backend/asyncThunks/companyBusinessProfileFromKycGet'
import { companyBusinessProfileGet } from '../../../../store/kyc-backend/asyncThunks/companyBusinessProfileGet'
import { companyBusinessProfileDraftGet } from '../../../../store/kyc-backend/asyncThunks/companyBusinessProfileDraftGet'
import { companyBusinessProfileSnapshotsGet } from '../../../../store/kyc-backend/asyncThunks/companyBusinessProfileSnapshotsGet'
import { individualBusinessProfileSnapshotsGet } from '../../../../store/kyc-backend/asyncThunks/individualBusinessProfileSnapshotsGet'
import { companyBusinessProfileSnapshotCreate } from '../../../../store/kyc-backend/asyncThunks/companyBusinessProfileSnapshotCreate'
import { individualProfileBackGet } from '../../../../store/kyc-backend/asyncThunks/individualProfileBackGet'
import { individualBusinessProfileFromKycGet } from '../../../../store/kyc-backend/asyncThunks/individualBusinessProfileFromKycGet'
import { individualBusinessProfileGet } from '../../../../store/kyc-backend/asyncThunks/individualBusinessProfileGet'
import { individualBusinessProfileDraftGet } from '../../../../store/kyc-backend/asyncThunks/individualBusinessProfileDraftGet'
import { individualBusinessProfileSnapshotCreate } from '../../../../store/kyc-backend/asyncThunks/individualBusinessProfileSnapshotCreate'
import { companyBusinessProfileUpdate } from '../../../../store/kyc-backend/asyncThunks/companyBusinessProfileUpdate'
import { individualBusinessProfileUpdate } from '../../../../store/kyc-backend/asyncThunks/individualBusinessProfileUpdate'
import { 
	individualBusinessProfileSnapshotsGetClear, 
	companyBusinessProfileSnapshotsGetClear, 
	individualBusinessProfileGetClear, 
	companyBusinessProfileGetClear, 
	individualBusinessProfileSnapshotCreateClear, 
	companyBusinessProfileSnapshotCreateClear,
	companyBusinessProfileDraftGetClear,
	individualBusinessProfileDraftGetClear,
	individualBusinessProfileApproveUpdateClear,
	companyBusinessProfileApproveUpdateClear,
	companyBusinessProfileUpdateClear,
	individualBusinessProfileUpdateClear,
	companyBusinessProfileFromKycGetClear,
	individualBusinessProfileFromKycGetClear,
	BusinessProfileResponse
} from '../../../../store/kyc-backend/slices/businessProfileSlice'
import { setFormUpdated } from '../../../../store/kyc-front/slices/navSlice'
import FormSelect from '../../../kyc-front/ui-elements/FormSelect'
import { FormCheckboxRadio } from '../../../kyc-front/ui-elements/FormCheckboxRadio'
import SourceOfFunds from './SourceOfFunds'
import PurposeOfEntity from './PurposeOfEntity'
import DirectorsTable from './DirectorsTable'
import BusinessProfileForm from './BusinessProfileForm'
import BusinessProfileHistoryTable from './BusinessProfileHistoryTable'
import BusinessProfilePrint from './BusinessProfilePrint'
import { ButtonUpper } from '../../../kyc-front/ui-elements/Buttons'
import { SuccessInfo, SuccessIcon } from '../../../kyc-front/ui-elements/SuccessInfo'
import BasicFormWrapper from '../../../kyc-front/ui-elements/BasicForm'
import FormInput from '../../../kyc-front/ui-elements/FormInput'
import FormDate from '../../../kyc-front/ui-elements/FormDate'
import { DividerFirst } from '../../../kyc-front/ui-elements/Dividers'
import Well from '../../../kyc-front/ui-elements/Well'
import { uppercaseFirstLetter } from '../../../../utilities/nameParsers'
import { themeColor } from '../../../../styles/themeStyles'
import FormUpload from '../../../kyc-front/ui-elements/FormUpload'
import styled from 'styled-components'
import KycLayout from '../../../kyc-front/kyc-layout/KycLayout'

interface KProps {
    isEditable: boolean, 
    label: string, 
    name: string, 
    kycName?: string, 
    error: string, 
    handleInputChange: any, 
    disabled: boolean, 
    value: string, 
    kycValue?: string,
    spanCol?: number
}

interface BProps {
    isArchived?: boolean,
}

type ApprovalsType = {
	clientAdvisorApprovedBy?: string,
	clientAdvisorApprovedAt?: string,
	complianceOfficeApprovedBy?: string,
	complianceOfficeApprovedAt?: string
}

type SnapStateType = {
	isApproved: boolean,
	isDraft: boolean,
	isLatestApproved: boolean,
	isEditable: boolean
}

type CreationType = {
	createdAt: string,
	dataVersion: string
}


const CenteredCol = styled(Col)`
	text-align: center;
	margin-bottom: 0;
	margin-top: 40px;
`

const Summary = styled.p`
	span {
		//width: 120px;
		font-size: 16px;
		display: inline-block;
		&.value {
			width: auto;
			display: inline-block;
			margin-left: 5px;
		}
	}
`

const KycInputElement: FC<KProps> = ({isEditable, label, name, kycName, error, handleInputChange, disabled, value, kycValue, spanCol}) => (
		<Row gutter={30}>
        	<Col span={spanCol ? spanCol : isEditable ? 12 : 24}>
        		<FormInput showApprove={false} label={label} name={name}  error={error} onChange={handleInputChange} disabled={true} printRender={false} value={value} type='company'  />
        	</Col>
        	{isEditable && <Col span={12}>
	        		<FormInput showApprove={false} label={label} name={kycName ? kycName : ''} disabled={true} printRender={false} value={kycValue ? kycValue : ''} type='company'  />
	        	</Col>
	        }
        </Row>
)




const BusinessProfile: FC<BProps> = ({isArchived}) => {
	const navigate = useNavigate();
	const envs = useSelector((state) => state.general.envs);
	const businessRelation = useSelector((state) => state.admin.businessRelation);
	const individualProfile = useSelector((state) => state.admin.backIndividual);
	const company = useSelector((state) => state.admin.backCompany);
	const companyProfile = company.profile.profile;
	const businessProfile = useSelector((state) => state.admin.businessProfile);
	const printRender = false;
	const showApprove = false;
	const fxAccount = useSelector((state) => state.admin.fxAccount);
	const isBusiness = envs.accountType === 'BUSINESS';
	const [dataLoaded, setDataLoaded] = useState(false);
	const adminProfile = useSelector((state) => state.admin.admin);
	const [showSOF, setShowSOF] = useState(false);
	const [showPOE, setShowPOE] = useState(false);
	const [visible, setVisible] = useState(false);
	const [isBack, setBack] = useState(false);
	const [isKycData, setIsKycData] = useState(false);
	const [initialDataLoaded, setInitialDataLoaded] = useState(false);
	const [initialData, setInitialData] = useState<any>({}); 
	const [shouldReload, setShouldReload] = useState(false);
	const [createNew, setCreateNew] = useState(false);
	const [creatingNewStatus, setCreatingNewStatus] = useState(false);
	const [statusName, setStatusName] = useState('Not initialized');
	const [popupVisible, setPopupVisible] = useState(false);
	const [loadKycData, setLoadKycData] = useState(false);
	const [shouldReloadListing, setShouldReloadListing] = useState(true);
	const [finalApprove, setFinalApprove] = useState(false);
	const pathParams = useParams();
	//@ts-ignore
	const [snapshotId, setSnapshotId] = useState(pathParams && pathParams[1] ? pathParams[1] : '');
	const [approvals, setApprovals] = useState<ApprovalsType>({});
	const [creationAndVersion, setCreationAndVersion] = useState<CreationType>({
		dataVersion: '',
		createdAt: ''
	});
	const [snapStatus, setSnapStatus] = useState<SnapStateType>({
		isApproved: false,
		isDraft: false,
		isLatestApproved: false,
		isEditable: false
	});
	const businessProfileSnapshotCreateStatus = useActionStatus(isBusiness ? companyBusinessProfileSnapshotCreate : individualBusinessProfileSnapshotCreate);
	const businessProfileSnapshotUpdateStatus = useActionStatus(isBusiness ? companyBusinessProfileUpdate : individualBusinessProfileUpdate);
	
	const { Option } = Select;
	//@ts-ignore
	let businessProfileState = isBusiness ? businessProfile?.businessProfileCompany : businessProfile?.businessProfileIndividual;
	let businessProfileKyc = isBusiness ? businessProfile?.businessProfileCompanyKYC : businessProfile?.businessProfileIndividualKYC;
	let basicInformation = initialData?.basicInformation;
	let sourceOfFunds = initialData?.sourceOfFunds;
	let purposeOfEntity = initialData?.purposeOfEntity;
	let basicInformationKyc = businessProfileKyc?.basicInformation;
	let authorizedPersonsUBOsKyc: any = businessProfileKyc?.authorizedPersonsUBOs;
	let sourceOfFundsUser = businessProfileKyc?.sourceOfFunds;
	let purposeOfEntityUser = businessProfileKyc?.purposeOfEntity;
	let authorizedPersonsUBOs: any = initialData?.authorizedPersonsUBOs;
	const initialValues = { 
		basicInformation: {
			name: basicInformation?.name, 
	        legalForm: basicInformation?.legalForm, 
	        publicKey: basicInformation?.publicKey,
	        address: basicInformation?.address ?? '',
	        postcode: basicInformation?.postcode ?? '', 
	        city: basicInformation?.city, 
	        country: basicInformation?.country,
	        dateOfIncorporation: basicInformation?.dateOfIncorporation || undefined, 
	        registrationNumber: basicInformation?.registrationNumber, 
	        placeOfIncorporation: basicInformation?.placeOfIncorporation
		},
		sourceOfFunds: {
			fundingOfBusinessActivities: sourceOfFunds?.fundingOfBusinessActivities,
			furtherExplanation: sourceOfFunds?.furtherExplanation,
			effectiveContributorOfFunds: sourceOfFunds?.effectiveContributorOfFunds,
			businessBackgroundOfContributor: sourceOfFunds?.businessBackgroundOfContributor
		},
		purposeOfEntity: {
			banks: purposeOfEntity?.banks,
			estimatedOfCryptoTransactionsEur: purposeOfEntity?.estimatedOfCryptoTransactionsEur,
			purposeOfBusinessRelationship: purposeOfEntity?.purposeOfBusinessRelationship,
			approximateAnnualTurnoverEur: purposeOfEntity?.approximateAnnualTurnoverEur,
			countriesOfOperation: purposeOfEntity?.countriesOfOperation,
			businessDescription: purposeOfEntity?.businessDescription,
			website: purposeOfEntity?.website
		},
		authorizedPersonsUBOs: {
			directors: authorizedPersonsUBOs?.directors,
			otherAuthorizedPersons: authorizedPersonsUBOs?.otherAuthorizedPersons,
			beneficialOwners: authorizedPersonsUBOs?.beneficialOwners
		},
		user: {
			basicInformation: {
				name: basicInformationKyc?.name, 
		        legalForm: basicInformationKyc?.legalForm, 
		        publicKey: basicInformationKyc?.publicKey,
		        address: basicInformationKyc?.address ?? '',
		        postcode: basicInformationKyc?.postcode ?? '', 
		        city: basicInformationKyc?.city, 
		        country: basicInformationKyc?.country,
		        dateOfIncorporation: basicInformationKyc?.dateOfIncorporation || undefined, 
		        registrationNumber: basicInformationKyc?.registrationNumber, 
		        placeOfIncorporation: basicInformationKyc?.placeOfIncorporation
			},
			sourceOfFunds: {
				fundingOfBusinessActivities: sourceOfFundsUser?.fundingOfBusinessActivities,
				furtherExplanation: sourceOfFundsUser?.furtherExplanation,
				effectiveContributorOfFunds: sourceOfFundsUser?.effectiveContributorOfFunds,
				businessBackgroundOfContributor: sourceOfFundsUser?.businessBackgroundOfContributor
			},
			purposeOfEntity: {
				estimatedOfCryptoTransactionsEur: purposeOfEntityUser?.estimatedOfCryptoTransactionsEur,
				approximateAnnualTurnoverEur: purposeOfEntityUser?.approximateAnnualTurnoverEur,
				countriesOfOperation: purposeOfEntityUser?.countriesOfOperation ? purposeOfEntityUser?.countriesOfOperation : '',
				businessDescription: purposeOfEntityUser?.businessDescription,
				website: purposeOfEntityUser?.website
			}
		}
       
    }
    const initialKycValues = { 
		basicInformation: {
			name: basicInformationKyc?.name, 
	        legalForm: basicInformationKyc?.legalForm, 
	        publicKey: basicInformationKyc?.publicKey,
	        address: basicInformationKyc?.address ?? '',
	        postcode: basicInformationKyc?.postcode ?? '', 
	        city: basicInformationKyc?.city, 
	        country: basicInformationKyc?.country,
	        dateOfIncorporation: basicInformationKyc?.dateOfIncorporation || undefined, 
	        registrationNumber: basicInformationKyc?.registrationNumber, 
	        placeOfIncorporation: basicInformationKyc?.placeOfIncorporation
		},
		sourceOfFunds: {
			fundingOfBusinessActivities: '',
			furtherExplanation: '',
			effectiveContributorOfFunds: '',
			businessBackgroundOfContributor: ''
		},
		purposeOfEntity: {
			banks: '',
			estimatedOfCryptoTransactionsEur: '',
			purposeOfBusinessRelationship: '',
			approximateAnnualTurnoverEur: '',
			countriesOfOperation: '',
			businessDescription: '',
			website: purposeOfEntityUser?.website
		},
		authorizedPersonsUBOs: {
			directors: authorizedPersonsUBOsKyc?.directors,
			otherAuthorizedPersons: authorizedPersonsUBOsKyc?.otherAuthorizedPersons,
			beneficialOwners: authorizedPersonsUBOsKyc?.beneficialOwners
		},
		user: {
			sourceOfFunds: {
				fundingOfBusinessActivities: sourceOfFundsUser?.fundingOfBusinessActivities,
				furtherExplanation: sourceOfFundsUser?.furtherExplanation,
				effectiveContributorOfFunds: sourceOfFundsUser?.effectiveContributorOfFunds,
				businessBackgroundOfContributor: sourceOfFundsUser?.businessBackgroundOfContributor
			},
			purposeOfEntity: {
				estimatedOfCryptoTransactionsEur: purposeOfEntityUser?.estimatedOfCryptoTransactionsEur,
				approximateAnnualTurnoverEur: purposeOfEntityUser?.approximateAnnualTurnoverEur,
				countriesOfOperation: purposeOfEntityUser?.countriesOfOperation ? purposeOfEntityUser?.countriesOfOperation : '',
				businessDescription: purposeOfEntityUser?.businessDescription
			}
		}
       
    }
    
    const setBusinessProfileData = (profileData: any) => {
    	setInitialData(JSON.parse(profileData.data));
    	setCreationAndVersion({
    		createdAt: profileData.createdAt,
    		dataVersion: profileData.dataVersion.BusinessProfileV1 ? 'V1' : 'V1'
    	})
    	setIsKycData(false);
    	setSnapshotId(profileData.snapshotId);
    	setStatuses(profileData);
    	//@ts-ignore
    	setApprovalsFunc(profileData);
    	setShouldReload(true);
    }
    const handleHistoryShow = (historicalData: any) => {
		setInitialDataLoaded(false);
		setBusinessProfileData(historicalData);
		setVisible(false);
	}
	const handleBack = () => {
		setDataLoaded(false);
		setBack(true);
	}
	const showDrawer = () => {
	    setVisible(true);
	};

	const onClose = () => {
	    setVisible(false);
	};

	const newProfileClicked = () => {
		setIsKycData(true);
		setCreateNew(true);
	}
	
	useEffect(() => {
    	if(createNew) {
    		if(isBusiness) {
				dispatch(companyBusinessProfileSnapshotCreate({params: {companyId: envs.profileId}, data: initialKycValues})).then((el) => {
					//@ts-ignore
					navigate(envs.routeSuffix + '/' + envs.profileId + '/business-profile/' + el.payload.snapshotId);
					handleHistoryShow(el.payload);
					setShouldReloadListing(true);
				})
			} else {
				dispatch(individualBusinessProfileSnapshotCreate({params: {individualId: envs.profileId}, data: initialKycValues})).then((el) => {
					//@ts-ignore
					navigate(envs.routeSuffix + '/' + envs.profileId + '/business-profile/' + el.payload.snapshotId);
					handleHistoryShow(el.payload);
					setShouldReloadListing(true);
				})
			}
			setCreateNew(false);
			
    	}
    	
    }, [createNew]);
    useEffect(() => {
    	if(isBack && dataLoaded === false) {
    		callInitialFunctions();
    		setBack(false)
    	}
    	
    }, [dataLoaded]);
    useEffect(() => {
    	if(shouldReload) {
    		setShouldReload(false);
    	}
    	
    }, [shouldReload]);
    useEffect(() => {
    	if(shouldReloadListing) {
	    	if(businessProfileSnapshotCreateStatus.isFullfilled && !businessProfileSnapshotCreateStatus.isCleared) {
	    		if(isBusiness) {
	    			dispatch(companyBusinessProfileSnapshotsGet({params: {companyId: envs.profileId}}))
	    		} else {
	    			dispatch(individualBusinessProfileSnapshotsGet({params: {individualId: envs.profileId}}))
	    		}
	    		setShouldReloadListing(false);
	    	}
	    }
    }, [businessProfileSnapshotCreateStatus]);
    useEffect(() => {
    	if(shouldReloadListing) {
    		if(businessProfileSnapshotUpdateStatus.isFullfilled && !businessProfileSnapshotUpdateStatus.isCleared) {
	    		if(isBusiness) {
	    			dispatch(companyBusinessProfileSnapshotsGet({params: {companyId: envs.profileId}}))
	    		} else {
	    			dispatch(individualBusinessProfileSnapshotsGet({params: {individualId: envs.profileId}}))
	    		}
	    		setShouldReloadListing(false);
	    		
	    	}
    	}
    	
    }, [businessProfileSnapshotUpdateStatus]);
    
    useEffect(() => {
    	if(loadKycData) {
    		setInitialDataLoaded(true);
			setInitialData(initialKycValues);
    		setShouldReload(true);
			setIsKycData(true);
			setLoadKycData(false);
    	}
	},[loadKycData])
    useEffect(() => {
    	dispatch(setFormUpdated({updated: false, form: []}));
    	return(() => {
    		dispatch(companyBusinessProfileGetClear({}));
    		dispatch(individualBusinessProfileGetClear({}));
    		dispatch(companyBusinessProfileSnapshotsGetClear({}));
    		dispatch(individualBusinessProfileSnapshotsGetClear({}));
    		dispatch(companyBusinessProfileFromKycGetClear({}));
    		dispatch(individualBusinessProfileFromKycGetClear({}));
    		dispatch(companyBusinessProfileSnapshotCreateClear({}));
    		dispatch(individualBusinessProfileSnapshotCreateClear({}));
    		dispatch(companyBusinessProfileApproveUpdateClear({}));
    		dispatch(individualBusinessProfileApproveUpdateClear({}));
    		dispatch(companyBusinessProfileUpdateClear({}));
    		dispatch(individualBusinessProfileUpdateClear({}));
    	});
    }, []);
    useEffect(() => {
    	//@ts-ignore
		if(businessProfile.businessProfileIndividualApproved.error) {
			message.error('Some error occured while updating this element');
		} else {
			if(businessProfile.businessProfileIndividualApproved.snapshotId !== "") {
				message.success('This element was sucessfully updated');
				dispatch(individualBusinessProfileApproveUpdateClear({}));
				if(businessProfile.businessProfileIndividualApproved.clientAdvisorApprovedBy && businessProfile.businessProfileIndividualApproved.complianceOfficeApprovedBy) {
					setFinalApprove(true);
					dispatch(individualBusinessProfileGet({params: {individualId: envs.profileId}})).then((resp) => {
		        		//@ts-ignore
		        		if(resp.payload !== "" && !resp.error) {
		        			setBusinessProfileData(resp.payload);
		        			setInitialDataLoaded(true);
		        		}
		        	})
				} else {
					getDraftIndividual();
				}
				
			}
		}
    }, [businessProfile.businessProfileIndividualApproved]);
    useEffect(() => {
		//@ts-ignore
		if(businessProfile.businessProfileCompanyApproved.error) {
			message.error('Some error occured while updating this element');
		} else {
			if(businessProfile.businessProfileCompanyApproved.snapshotId !== "") {
				message.success('This element was sucessfully updated');
				dispatch(companyBusinessProfileApproveUpdateClear({}));
				if(businessProfile.businessProfileCompanyApproved.clientAdvisorApprovedBy && businessProfile.businessProfileCompanyApproved.complianceOfficeApprovedBy) {
					setFinalApprove(true);
					dispatch(companyBusinessProfileGet({params: {companyId: envs.profileId}})).then((resp) => {
		        		//@ts-ignore
		        		if(resp.payload !== "" && !resp.error) {
		        			setBusinessProfileData(resp.payload);
		        			setInitialDataLoaded(true);
		        		}
		        	})
				} else {
					getCompanyDraft();
				}
				
			}
		}
    }, [businessProfile.businessProfileCompanyApproved]);
    useEffect(() => {
		if(businessProfile.newBusinessProfileCompanySnapshot) {
			if(isKycData && businessProfile.newBusinessProfileCompanySnapshot.snapshotId !== "") {
				setBusinessProfileData(businessProfile.newBusinessProfileCompanySnapshot);
				dispatch(companyBusinessProfileSnapshotCreateClear({}));
			}
		}
    }, [businessProfile.newBusinessProfileCompanySnapshot]);

    useEffect(() => {
		if(businessProfile.newBusinessProfileIndividualSnapshot) {
			if(isKycData && businessProfile.newBusinessProfileIndividualSnapshot.snapshotId !== "") {
				setBusinessProfileData(businessProfile.newBusinessProfileIndividualSnapshot);
				dispatch(individualBusinessProfileSnapshotCreateClear({}));
			}
		}
    }, [businessProfile.newBusinessProfileIndividualSnapshot]);
    
	useEffect(() => {
		callInitialFunctions();
        
    }, [envs.accountType]);
    const handleConfirmVisibleChange = () => {
        setPopupVisible(!popupVisible);
    }
    const cancelConfirm = () => {
        setPopupVisible(false);
    }
    const confirmNew = () => {
    	setPopupVisible(false);
    	newProfileClicked();
    }
    const callInitialFunctions = () => {
    	if(dataLoaded === false && envs.accountType !== '') {
			if(isArchived) {
				if(isBusiness) {
					dispatch(companyProfileBackGet({ params: { companyId: envs.profileId} }));
		        	dispatch(companyBusinessProfileFromKycGet({params: {companyId: envs.profileId}}));
		        	dispatch(companyBusinessProfileSnapshotsGet({ params: { companyId: envs.profileId} })).then((resp) => {
		        		//@ts-ignore
		        		let targetSnapshot = resp?.payload.find((elem: any) => elem.snapshotId === snapshotId);
		        		if(targetSnapshot) {
		        			setInitialDataLoaded(true);
		        			setBusinessProfileData(targetSnapshot);
		        		} else {
		        			navigate(envs.routeSuffix + '/' + envs.profileId + '/business-profile');
		        		}
		        	})
				}
				if(envs.accountType === 'PRIVATE') {
					
					dispatch(individualProfileBackGet({params: {individualId: envs.profileId}}));
		        	dispatch(individualBusinessProfileFromKycGet({params: {individualId: envs.profileId}}));
		        	dispatch(individualBusinessProfileSnapshotsGet({ params: { individualId: envs.profileId} })).then((resp) => {
		        		//@ts-ignore
		        		let targetSnapshot = resp?.payload.find((elem: any) => elem.snapshotId === snapshotId);
		        		if(targetSnapshot) {
		        			setInitialDataLoaded(true);
		        			setBusinessProfileData(targetSnapshot);
		        		} else {
		        			navigate(envs.routeSuffix + '/' + envs.profileId + '/business-profile');
		        		}
		        	})
				}
				setDataLoaded(true);
			} else {
				if(isBusiness) {
		        	dispatch(companyProfileBackGet({ params: { companyId: envs.profileId} }));
		        	dispatch(companyBusinessProfileFromKycGet({params: {companyId: envs.profileId}}));
		        	dispatch(companyBusinessProfileGet({params: {companyId: envs.profileId}})).then((resp) => {
		        		//@ts-ignore
		        		if(resp.payload !== "" && !resp.error) {
		        			setInitialDataLoaded(true);
		        			setBusinessProfileData(resp.payload);
		        		} else {
		        			getCompanyDraft();
		        		}
		        	})
		        }
		        if(envs.accountType === 'PRIVATE') {
		        	dispatch(individualProfileBackGet({params: {individualId: envs.profileId}}));
		        	dispatch(individualBusinessProfileFromKycGet({params: {individualId: envs.profileId}}));
		        	dispatch(individualBusinessProfileGet({params: {individualId: envs.profileId}})).then((resp) => {
		        		//@ts-ignore
		        		if(resp.payload !== "" && !resp.error) {
		        			setInitialDataLoaded(true);
		        			setBusinessProfileData(resp.payload);
		        		} else {
							getDraftIndividual();
		        		}
		        	})
		        }
		        setDataLoaded(true);
		    }
		}
    }
    const getDraftIndividual = () => {
    	dispatch(individualBusinessProfileDraftGet({params: {individualId: envs.profileId}})).then((respDraft) => {
			//@ts-ignore
			if(respDraft.payload !== "" && !respDraft.error) {
    			setInitialDataLoaded(true);
    			setBusinessProfileData(respDraft.payload);
    		} else {
    			setLoadKycData(true);
    		}
		})
    }
    const getCompanyDraft = () => {
    	dispatch(companyBusinessProfileDraftGet({params: {companyId: envs.profileId}})).then((respDraft) => {
			//@ts-ignore
			if(respDraft.payload !== "" && !respDraft.error) {
    			setInitialDataLoaded(true);
    			setBusinessProfileData(respDraft.payload);
    		} else {
    			setLoadKycData(true);
    		}
		})
    }
    const setApprovalsFunc = (resp: BusinessProfileResponse) => {
    	setApprovals({
			//@ts-ignore
			clientAdvisorApprovedAt: resp?.clientAdvisorApprovedAt,
			//@ts-ignore
			clientAdvisorApprovedBy: resp?.clientAdvisorApprovedBy,
			//@ts-ignore
			complianceOfficeApprovedBy: resp?.complianceOfficeApprovedBy,
			//@ts-ignore
			complianceOfficeApprovedAt: resp?.complianceOfficeApprovedAt
		});
    }
    const hideFinalApprove = () => {
    	setFinalApprove(false);
    	navigate(envs.routeSuffix + '/' + envs.profileId + '/business-profile');

    }
    const setStatuses = (el?: any) => { 
    	if(el) {
    		setSnapStatus({
				isApproved: el.isApproved,
				isDraft: el.isDraft,
				isLatestApproved: el.isLatestApproved,
				isEditable: el.isEditable
			});
    	}
    	
    }
    let disabled = snapStatus.isEditable ? false : isKycData ? false : true;
    let isEditable = snapStatus.isEditable ? true : isKycData ? true : false;
	return (
        (<KycLayout nav={{hideNav: true, hideSteps: true, navSubpage: 'business-profile'}}>
            {finalApprove ? (
				<div>
			            <Row justify='center'>
			                <CenteredCol>
			                    <SuccessIcon />
			                    <p><b>Approved!</b></p>
			                    <p>Your have succesfully approved this Business Profile</p>
			                </CenteredCol>
			            </Row>
			            <Row justify='center' style={{'marginTop': '20px', 'marginBottom': '40px'}}>
			                <CenteredCol>
			                    <ButtonUpper onClick={hideFinalApprove} btnType='default'>View Business Profile</ButtonUpper>
			                </CenteredCol>
			            </Row>
			    </div>
		    ) : (
			    <>
					{!shouldReload &&
						<Row gutter={30}>
							<Col span={24}>
								<Row justify='space-between'style={{'position': 'relative', 'top' : '-35px'}}>
									<Text style={{'fontSize': '25px', 'fontWeight':'bold'}}>Business Profile status: 
										<Text style={{color: snapStatus.isLatestApproved ? themeColor.green : snapStatus.isEditable ? themeColor.blue : snapStatus.isApproved ? themeColor.greenGray : snapStatus.isDraft ? themeColor.blueGray : themeColor.gray}}><b> {snapStatus.isLatestApproved ? 'Approved' : snapStatus.isEditable ? 'Drafted' : snapStatus.isApproved ? 'Archived approved' : snapStatus.isDraft ? 'Archived draft' : 'Not initialized'}</b></Text>
									</Text>
									<div>
										{isArchived && <Link to={envs.routeSuffix + '/' + envs.profileId + '/business-profile'} style={{marginRight: 10}}><ButtonUpper btnType='primary' ghost onClick={() => handleBack()}><CaretLeftOutlined />Back to latest</ButtonUpper></Link>}
										<span style={{marginRight: 10}}><ButtonUpper btnType='default' onClick={showDrawer}>History</ButtonUpper></span>
										<Popconfirm
					                        title="Are You sure you want to create new Business Profile? This will override newest draft."
					                        visible={popupVisible}
					                        onVisibleChange={handleConfirmVisibleChange}
					                        onConfirm={confirmNew}
					                        onCancel={cancelConfirm}
					                        okText="Yes, create"
					                        cancelText="Cancel"
					                        icon={<QuestionCircleOutlined />}
					                    >
											<ButtonUpper btnType='primary' disabled={isKycData}>New Profile</ButtonUpper>
										</Popconfirm>
									</div>
								</Row>
								<Row className='hide-print'>
									<Col span={24} style={{marginTop: -20, marginBottom: 20}}>
										<Divider />
										<Title level={4} style={{'marginBottom': '20px', 'marginTop': '30px'}}>Profile Summary</Title>
										<Summary>
											<span>Created at:</span>
											<span className='value'><b>{creationAndVersion.createdAt === '' ? 'Not created' : moment(creationAndVersion.createdAt).format('YYYY-MM-DD HH:mm:ss')}</b></span>
										</Summary>
										{snapStatus.isApproved ? (
											<Summary>
												<span>Approved at:</span>
												<span className='value'><b>{moment(approvals.clientAdvisorApprovedAt).isAfter(approvals.complianceOfficeApprovedAt) ? moment(approvals.clientAdvisorApprovedAt).format('YYYY-MM-DD HH:mm:ss') : moment(approvals.complianceOfficeApprovedAt).format('YYYY-MM-DD HH:mm:ss')}</b></span>
											</Summary>
											) : (<></>)
										}
										<Summary>
											<span>Version:</span>
											<span className='value'><b>{creationAndVersion.dataVersion === '' ? 'Not created' : creationAndVersion.dataVersion}</b></span>
										</Summary>
									</Col>
								</Row>
								<Drawer title="Business Profile History" size='large' placement="right" onClose={onClose} open={visible}>
							        <BusinessProfileHistoryTable handleHistoryShow={handleHistoryShow} />
							    </Drawer>
						        <BusinessProfileForm 
						        	printRender={false}
						        	initialValues={initialValues} 
						        	snapStatus={snapStatus} 
						        	isEditable={isEditable} 
						        	disabled={disabled} 
						        	showApprove={showApprove} 
						        	authorizedPersonsUBOs={authorizedPersonsUBOs}
						        	approvals={approvals}
						        	snapshotId={snapshotId}
						        >
						        	<div style={{marginBottom: 30, display: snapStatus.isApproved ? 'block' : 'none'}}> 
							        	<Divider />
								        <Title level={3}>Download Business Profile</Title>
								        <BusinessProfilePrint approvals={approvals}>
								        	<BusinessProfileForm 
									        	printRender={true}
									        	initialValues={initialValues} 
									        	snapStatus={snapStatus} 
									        	isEditable={isEditable} 
									        	disabled={false} 
									        	showApprove={showApprove} 
									        	authorizedPersonsUBOs={authorizedPersonsUBOs}
									        	approvals={approvals}
									        	snapshotId={snapshotId}
									        />
								        </BusinessProfilePrint>
							        </div>
						        </BusinessProfileForm>
						        
							</Col>
						</Row>
					}
				</>
			)}
        </KycLayout>)
    );
}

export default BusinessProfile
