import { createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../../api/kyc/api";
import { AsyncThunkActionType } from "../../store";
import { AsyncThunkDataType } from "../../common";
import { UboResponse } from "../slices/ubosSlice";
import { Components, Paths } from "../../../api/kyc/generated/client";

type CreateUboRequest = Components.Schemas.NewBeneficialOwner;
type PathParameters = Paths.CreateBeneficialOwner.PathParameters;

export const uboCreate: AsyncThunkActionType<
  UboResponse,
  AsyncThunkDataType<PathParameters, CreateUboRequest, "data">
> = createAsyncThunk(
  "uboCreate",
  async ({ params, data, config }, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await api.CreateBeneficialOwner(params, data, config);
      return response.data as UboResponse
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);
