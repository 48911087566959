import { createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../../api/kyc-backend/api";
import { AsyncThunkActionType } from "../../store";
import { AsyncThunkDataType } from "../../common";
import { IndividualValidateResponse } from "../slices/statusSlice";
import { Components, Paths } from "../../../api/kyc-backend/generated/client";

export const individualBackValidate: AsyncThunkActionType<
  IndividualValidateResponse,
  AsyncThunkDataType<null, null, "params">
> = createAsyncThunk(
  "individualBackValidate",
  async ({ params, data, config }, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      const response = await api.IndividualProfileValidate(params, null, config);
      return response.data as IndividualValidateResponse;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);
