import { Space, Button, Divider, Row, Col, message, Popconfirm } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { FC, useEffect, useState, ReactNode } from 'react'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { dispatch } from '../../../store/store'
import { setCurrentEditedForm, setEditedFormConfirmed, setFormUpdated } from '../../../store/kyc-front/slices/navSlice'
import { approveCompanyToggle } from '../../../store/kyc-backend/asyncThunks/approveCompanyToggle'
import { approveIndividualToggle } from '../../../store/kyc-backend/asyncThunks/approveIndividualToggle'
import { individualArchive } from '../../../store/kyc-backend/asyncThunks/individualArchive'
import { companyArchive } from '../../../store/kyc-backend/asyncThunks/companyArchive'
import { companyProfileBackGet } from '../../../store/kyc-backend/asyncThunks/companyProfileBackGet'
import { individualProfileBackGet } from '../../../store/kyc-backend/asyncThunks/individualProfileBackGet'
import { themeColor } from '../../../styles/themeStyles'
import { useSelector } from '../../../hooks/useSelector'
import { ButtonUpper, ButtonWrapper } from '../ui-elements/Buttons'
import { ColRight } from '../ui-elements/Columns'
import { DividerFirst } from '../ui-elements/Dividers'
import { useActionStatus } from '../../../hooks/useActionStatus'
import Well from '../ui-elements/Well'

import { kycApplicationFinalApprove } from 'store/kyc-backend/asyncThunks/kycApplicationFinalApprove'
import { kycApplicationApprovalsGet } from 'store/kyc-backend/asyncThunks/kycApplicationApprovalsGet'
import { ADMIN_BASE_PATH, BASE_PATH } from 'routes/AppRoutes'
import { kycApplicationApprovalsUpdate } from 'store/kyc-backend/asyncThunks/kycApplicationApprovalsUpdate'
import { kycApplicationApprovalsUpdateClear } from 'store/kyc-backend/slices/backKycApplication'

const NavSpace = styled(Space)`
	background-color: #fff;
	padding: 15px 30px;
	margin-left: -30px;
	margin-right: -30px;
`
const ButtonUpperSuccess = styled(ButtonUpper)`
	border-color: ${themeColor.green};
	color: ${themeColor.green};
	&.ant-btn&:not(.ant-btn-primary) {
		&:hover, &:focus, &:active {
			border-color: ${themeColor.green};
			color: ${themeColor.green};
		}
	}
`
interface Approval {
	ref: string;
	slug: string;
	isApprovalRequirementSatisfied: boolean;
	approvedBy: string[];
}

interface Document {
	id: string;
	approvals: Approval[];
}
  
interface DocResponse {
	documents: Document[];
}

interface ResultDocument {
	id: string;
	kind: boolean;
}
  
interface Result {
	documents: ResultDocument[];
}

interface IProps {
	approve?: boolean;
	archive?: boolean;
	restore?: boolean;
	validationAction?: (value: any) => any;
	validationActionParams?: object;
	hideValidate?: boolean;
	hideSaveButton?: boolean;
	hideApproveStepButton?: boolean;
	setErrorsListing?: (value: any) => any;
}

export const KycNav: FC<IProps> = ({approve, archive, restore, validationAction, validationActionParams, hideValidate, hideSaveButton, hideApproveStepButton, setErrorsListing}) => {
	const nav = useSelector((state) => state.general.nav);
	const envs = useSelector((state) => state.general.envs);
	const profile = useSelector((state) => state.user.profile);
	const stateEl = useSelector((state) => state);
	const { validation, validationFullResponse, stepsScheme, kycApplication } = useSelector((state) => state.user2.kycApplication)
	const validationFullResponseValues = validationFullResponse.payload?.validationErrors
	const { approvalErrors, multiApprove, kycApplicationApprovals  } = useSelector((state) => state.admin2.applicationApprovals);
	const validationStatusBack = useSelector((state) => state.admin.backStatus);
	let [popupArchiveVisible, setPopupArchiveVisible] = useState(false);
	const { userProfile } = profile;
	let {next, nextName, nextButton, prev, prevName, step} = nav.nav;
	let currentStepValidation = validation.find(stepEl => stepEl.link  === nav.nav.link)
	let {formUpdated} = nav;
	let [disabledApprove, setDisabledApprove] = useState(false);
	let [popupPrevVisible, setPopupPrevVisible] = useState(false);
	let [popupNextVisible, setPopupNextVisible] = useState(false);
	let [messageShown, setMessageShown] = useState(false);
	let [archiveMessageShown, setArchiveMessageShown] = useState(false);
	let [restoreMessageShown, setRestoreMessageShown] = useState(false);
    let isStandaloneAdmin = envs.admin && envs.type === 'standalone';
	const currentStep = nav.nav.step ?? 0;
	const apIndex = stepsScheme.findIndex(el => el.type === 'authorizedPersons');
	const apStep = stepsScheme[apIndex]?.step;
	const uboIndex = stepsScheme.findIndex(el => el.type === 'beneficialOwners');
	const uboStep = stepsScheme[uboIndex]?.step;
	const isApOrUbo = currentStep === apStep || currentStep === uboStep
	let el = useLocation()
	let apUboID = el.pathname.split('/')[envs.admin ? 4 : 3]
    //@ts-ignore
    const {isFullfilled, isError, isCleared} = useActionStatus(validationAction);
	const approvalStatusUpdate = useActionStatus(kycApplicationApprovalsUpdate);
    const archivingStatus = useActionStatus(envs.isBusiness ? companyArchive : individualArchive);
	const finalApproveStatus = useActionStatus(kycApplicationFinalApprove)
    useEffect(() => {
		if(archivingStatus.isFullfilled && !archivingStatus.isCleared) {
			if(archiveMessageShown || restoreMessageShown) {
				if(restoreMessageShown) {
					message.success('Profile was successfully restored');
					setRestoreMessageShown(false);
				}
				if(archiveMessageShown) {
					message.success('Profile was successfully archived');
					setArchiveMessageShown(false);
				}
				if(envs.isBusiness) {
					dispatch(companyProfileBackGet({params: {companyId: envs.profileId}}))
				}
				if(envs.isPrivate) {
					dispatch(individualProfileBackGet({params: {individualId: envs.profileId}}))
				}
			}
			
		}
    }, [archivingStatus]);
    useEffect(() => {
		if(isFullfilled && !isCleared) {
			let substepErrors = validation?.find((err => err.refId === nav.nav.refId && err.substep === nav.nav.substep));
			handleValidationMessageShow(isApOrUbo ? substepErrors?.validationErrors  : validation?.[currentStep - 1]?.validationErrors);
		}
    }, [validation]);
	useEffect(() => {
		const isValid = checkValidityOfKYC();
		if(isValid) {
			setDisabledApprove(false)
		}
	}, [validation, approvalErrors, validationFullResponseValues ])
     /*useEffect(() => {
		if(isFullfilled && !isCleared) {
			if(envs.isBusiness) {
				switch(nav.nav.step) {//todo change admin as in user messages
					case 1:
						handleValidationMessageShow(validationStatusBack?.companyValidated?.validationErrors);
						return;
					case 2:
						handleValidationMessageShow(validationStatusBack?.authorizedPersonsValidated, true);
						return;
					case 3:
						handleValidationMessageShow(validationStatusBack?.beneficialOwnersValidated, true);
						return;
					default:
						return;
				}
			}
			if(envs.isPrivate) {
				switch(nav.nav.step) {
					case 1:
						handleValidationMessageShow(validationStatusBack?.individualValidated?.validationErrors);
						return;
					default:
						return;
				}
			}
			
			
		}
    }, [validationStatusBack]);*/
	useEffect(() => {
		if(kycApplication.status === 'ACCEPTED') {
			setDisabledApprove(true)
		}
	}, [kycApplication])

	useEffect(() => {
        if(approvalStatusUpdate.isError || approvalStatusUpdate.isFullfilled) {
            //@ts-ignore
            if(multiApprove) {
                if(approvalStatusUpdate.isError) {
                    message.error('Some error occured while updating this element');
                }
                if(isFullfilled) {
                    message.success('This element was sucessfully updated');
                }
                dispatch(kycApplicationApprovalsUpdateClear({}))
                dispatch(kycApplicationApprovalsGet({params: {id: envs.profileId}}))
            }
        }
    }, [approvalStatusUpdate]);
    
	const handleValidationMessageShow = (el: any, isArray?: boolean) => {
		if(isArray) {
			let isValid = true;
			el.forEach((elemInner: any) => {
				if(elemInner.validationErrors.length) {
					isValid = false
					return
				}
			});
			if(isValid && messageShown) {
				message.success('Validation completed! No errors found');
				setMessageShown(false);
			}
		} else {
			if(el && el.length === 0 && messageShown) {
				message.success('Validation completed! No errors found');
				setMessageShown(false)
			}
		}
		
	}

	const cancel = () => {
		setPopupPrevVisible(false);
	  	setPopupNextVisible(false);
	}
	const confirm = (type: string) => {
		if(prev && type === 'prev') {
			dispatch(setEditedFormConfirmed({onConfirm: true, onConfirmLink: prev}));
		}
		if(next && type === 'next') {
			dispatch(setEditedFormConfirmed({onConfirm: true, onConfirmLink: next}));
		}
	  	if(type === 'save') {
			dispatch(setEditedFormConfirmed({onConfirm: true}));
		}
	}
	const approveStep = () => {
		const stepScheme = stepsScheme.find(el => el.link === nav.nav.link)
		if(stepScheme) {
			let approvalNames = getApprovalNames(stepScheme, currentStep === apStep ? 'authorizedPersons' : currentStep === uboStep ? 'beneficialOwners' : undefined)
			//@ts-ignore
			dispatch(kycApplicationApprovalsUpdate({params: {id: envs.profileId, multiApprove: true}, data: approvalNames}))
		}
	}
	const findDocumentsByName = (names: string[], response: DocResponse) => {
		const result: Result = { documents: [] };
		names.forEach((name) => {
			const slug = name.split('.')
		  	const slugToFind = name.split('.')[1]
		  	response?.documents?.forEach((document) => {
			const approvalMatch = document.approvals.some((approval) => approval.slug === slugToFind);
				if (approvalMatch) {
				result.documents.push({
					id: document.id,
					kind: true
				});
				}
		  	});
		});
		return result;
	}
	const getApprovalNames = (schemaParent: any, schemaName?: 'authorizedPersons' | 'beneficialOwners') => {
		let nameEl: any;
		let documentsApprovals;
		let isSignatory = false;
		const personEl = schemaName === 'authorizedPersons' ? kycApplicationApprovals.authorizedPersons.find((el: any) => el.id === apUboID) : schemaName === 'beneficialOwners' ? kycApplicationApprovals.beneficialOwners.find((el: any) => el.id === apUboID) : kycApplicationApprovals;
		if(schemaParent) {
			type NestedObject = {
				[key: string]: true | NestedObject;
			};
			const parsedSchema: NestedObject = {}
			documentsApprovals = findDocumentsByName(schemaParent.fields.filter((el: string) => el.includes('docs')), personEl)
			schemaParent.fields.forEach((el: string) => {
				let splitEl = el?.split('.');
				if (splitEl.length > 1) {
				  	let current: any = parsedSchema;
				  	if(splitEl[0] !== 'docs') {
						splitEl.forEach((part, index) => {
							if (index === splitEl.length - 1) {
							current[part] = index === 1 && splitEl.length === 3 ? { id: splitEl[1], [splitEl[2]]: true } : true;
							} else {
							if (!current[part]) {
								current[part] = {};
							}
							current = current[part];
							}
						});
					}
				} else {
				  if (el === 'sourceOfWealthBusinessActivities') {
					Object.assign(parsedSchema, {
					  sourceOfWealthBusinessActivities: true,
					  sourceOfWealthInheritance: true,
					  sourceOfWealthProfessionalOccupation: true,
					  sourceOfWealthOther: true,
					});
				  } else {
					if(el === 'isSignatory') {
						if(isSignatory === false) {
							isSignatory = true
						}
                        const signatoriesList = kycApplication.values.authorizedPersons?.filter((person: any) => person.isSignatory === "true");
                        nameEl = {authorizedPersons: []};
                        signatoriesList?.forEach((el: any) => nameEl.authorizedPersons.push({id: el.id, isSignatory: true}))
                    } else {
						parsedSchema[el] = true;
					}
				  }
				}
			})
            if(schemaName) {
                nameEl = {[schemaName]: [{id: apUboID, ...parsedSchema}]}
            } else {
				if(!isSignatory) {
                	nameEl = parsedSchema
				}
            }
            
        } else {
            if(schemaName) {
                nameEl = {[schemaName]: true}
            }
			
            
        }
		return {...nameEl, ...documentsApprovals}
	}
	const handleVisibleChange = (type: string) => {
		if(formUpdated.updated) {
			if(prev && type === 'prev') {
				dispatch(setEditedFormConfirmed({onConfirm: true, onConfirmLink: prev}));
			}
			if(next && type === 'next') {
				dispatch(setEditedFormConfirmed({onConfirm: true, onConfirmLink: next}));
			}
	      	
		}
	}
	const handleArchiveVisibleChange = () => {
        setPopupArchiveVisible(!popupArchiveVisible);
    }
    const cancelArchive = () => {
        setPopupArchiveVisible(false);
    }
    const confirmArchive = () => {
        setPopupArchiveVisible(false);
        setArchiveMessageShown(true);
        if(envs.isBusiness) {
        	dispatch(companyArchive({ params: {companyId: envs.profileId}, data: {archive: true}}))
        }
        if(envs.isPrivate) {
        	dispatch(individualArchive({ params: {individualId: envs.profileId}, data: {archive: true}}))
        }
    }
    const confirmRestore = () => {
    	setPopupArchiveVisible(false);
    	setRestoreMessageShown(true);
        if(envs.isBusiness) {
        	dispatch(companyArchive({ params: {companyId: envs.profileId}, data: {archive: false}}))
        }
        if(envs.isPrivate) {
        	dispatch(individualArchive({ params: {individualId: envs.profileId}, data: {archive: false}}))
        }
    }
	const validateParams = () => {
		if(validationAction) {
			setMessageShown(true);
			dispatch(validationAction({ params: validationActionParams }))
		}
    }
    const handleApprove = () => {
		if(envs.admin) {
			setDisabledApprove(true)
			const isValid = checkValidityOfKYC()
			if(!isValid) {
				if(setErrorsListing) {
					dispatch(kycApplicationApprovalsGet({ params: { id: envs.profileId } }))
					setErrorsListing(true)
				}
			} else {
				dispatch(kycApplicationFinalApprove({params: {id: envs.profileId}}))
			}
		}
	}
	const checkValidityOfKYC = () => {
		const validationErrors = validation.find(err => err.validationErrors.length)
		const approvingErrors = approvalErrors.find((err: any) => err.validationErrors.length)
		const approvingDocsErrors = approvalErrors.find((err: any) => err.docErrors.length)
		const directorError = validationFullResponseValues?.find((err: any) => err.ref === 'authorizedPersons' && err.slug === 'Director')
		const beneficialOwnerError = validationFullResponseValues?.find((err: any) => err.ref === 'beneficialOwners' && err.slug === 'required')
		const signatoryError = validationFullResponseValues?.find((err: any) => err.ref === 'authorizedPersons' && err.slug === 'signatories') 
		if(validationErrors || directorError || beneficialOwnerError || signatoryError || (envs.admin && (finalApproveStatus.isError || approvingErrors || approvingDocsErrors) )) {
			return false
		} else {
			return true
		}
	}
	const status = kycApplication.status
	return (
		<div>
	      	<NavSpace direction='vertical' size={'small'}>
	      		{(status === 'ACCEPTED' || status === 'SUBMITTED') && step === 0 ? (
	      			<Row>
		    			<Col md={24}>
		    				<Well>
	    						<b>If your details change, you can make changes to KYC.</b>
	    						<p>Please note that making some changes may prevent you from using Bitclear Applications until Bitclear AG approves the changes.</p>
	    						<Row justify="end">
				    				<Link to={isStandaloneAdmin ? envs.routeSuffix + '/' + envs.profileId + next : envs.routeSuffix + next}>
				    					<ButtonUpper btnType="primary" ghost={true}>Go to KYC</ButtonUpper>
				    				</Link>
			    				</Row>
			      		 	</Well>
		    			</Col>
	    			</Row>
	      		) : (
	      			<div>
			    		<Row>
			    			<Col md={12}>
			    				{prev &&
			    					<>
										<ButtonUpper onClick={() => confirm('prev')} btnType="primary" ghost>{prevName || 'Back'}</ButtonUpper>
			    					</>
			    					
				    			}
			    			</Col>
			    			<ColRight>
								<>
									{archive &&
										<Popconfirm
                                            title="Are You sure you want to archive this KYC profile?"
                                            onConfirm={confirmArchive}
                                            onCancel={cancelArchive}
                                            okText="Archive KYC"
                                            cancelText="Cancel"
                                            icon={<QuestionCircleOutlined />}
                                        >
                                            <ButtonUpper btnType="primary" className='left' ghost>Archive KYC</ButtonUpper>
                                        </Popconfirm>
									}
									{restore &&
										<Popconfirm
                                            title="Are You sure you want to restore this KYC profile?"
                                            onConfirm={confirmRestore}
                                            onCancel={cancelArchive}
                                            okText="Restore KYC"
                                            cancelText="Cancel"
                                            icon={<QuestionCircleOutlined />}
                                        >
                                            <ButtonUpper btnType="primary" className='left' ghost>Restore KYC</ButtonUpper>
                                        </Popconfirm>
									}
									{prev && next && !hideValidate &&<span style={{marginRight: 20}}><ButtonUpper btnType="text" onClick={validateParams} disabled={formUpdated.updated}>Validate</ButtonUpper></span>}
									{envs.admin && step && step > 0 && hideApproveStepButton !== true ?
										<span style={{marginRight: 20}}>
						      		 		<ButtonUpperSuccess btnType='default' size='small' onClick={() => approveStep()}>Approve</ButtonUpperSuccess>
										</span>
						      		: <></>}
									{step && step > 0 && hideSaveButton !== true ?
										<span style={{marginRight: 20}}>
						      		 		<ButtonUpper btnType="primary" size='small' onClick={() => confirm('save')} disabled={formUpdated.updated ? false : true}>Save</ButtonUpper>
										</span>
						      		: <></>}
									{next &&
				    					<>
											<ButtonUpper onClick={() => confirm('next')} btnType="primary" ghost>{nextName || 'Next'}</ButtonUpper>
					    				
					      		 		</>
					      		 	}
					      		 	{approve &&
						      		 	<ButtonUpper btnType="primary" size='small' onClick={handleApprove} disabled={disabledApprove}>Approve KYC</ButtonUpper>
						      		}
								</>
			      		 	</ColRight>
			    		</Row>
		    		</div>
	    		)}
	      	</NavSpace>
      	</div>
				      
	)
}

export default KycNav
