import { createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../../api/kyc/api";
import { AsyncThunkActionType } from "../../store";
import { AsyncThunkDataType } from "../../common";
import { Components, Paths } from "../../../api/kyc/generated/client";
import { store } from "../../store";

type CompanyResponse = Paths.CompanyInternalMessageViewed.Responses.$200;
type PathParameters = Paths.CompanyInternalMessageViewed.PathParameters;

export const companyMessageViewedPut: AsyncThunkActionType<
  CompanyResponse,
  AsyncThunkDataType<PathParameters, null, "data">
> = createAsyncThunk(
  "companyMessageViewedPut",
  async ({ params, data, config }, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      const response = await api.CompanyInternalMessageViewed(params, data, config);

      return response.data as CompanyResponse;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);
