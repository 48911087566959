import { createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../../api/kyc-backend/api";
import { AsyncThunkActionType } from "../../store";
import { AsyncThunkDataType } from "../../common";
import { IndividualResponse } from "../slices/finalApproveSlice";
import { Components, Paths } from "../../../api/kyc-backend/generated/client";

type ToggleRequest = Components.Schemas.ApproveRequest
type PathParameters = Paths.ApproveIndividual.PathParameters;

export const approveIndividualToggle: AsyncThunkActionType<
  IndividualResponse,
  AsyncThunkDataType<PathParameters, ToggleRequest, "data">
> = createAsyncThunk(
  "approveIndividualToggle",
  async ({ params, data, config }, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      const response = await api.ApproveIndividual(params, data, config);
      return response.data as IndividualResponse;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);
