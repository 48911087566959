import { createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../../api/kyc/api";
import { AsyncThunkActionType } from "../../store";
import { AsyncThunkDataType } from "../../common";
import { Components, Paths } from "../../../api/kyc/generated/client";

type CreateAuthorizedPersonDocsRequest = Components.Schemas.BeneficialOwnerDocumentForm;
type resp = Components.Schemas.BeneficialOwnerDocumentEntry;
type PathParameters = Paths.BeneficialOwnerDocument.PathParameters;

export const uboDocCreate: AsyncThunkActionType<
    resp,
    AsyncThunkDataType<PathParameters, CreateAuthorizedPersonDocsRequest, "data">
    > = createAsyncThunk(
    "uboDocCreate",
    async ({ params, data, config }, thunkAPI) => {
        const { rejectWithValue } = thunkAPI;
        try {
            const response = await api.BeneficialOwnerDocument(params, data, config);
            return response.data as resp;
        } catch (err: any) {
            return rejectWithValue({
                error: true,
                status: err?.response.status, 
                data: err?.response.data,
                ...config
            });
        }
    }
);
