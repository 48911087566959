import { ErrorMessage } from 'formik'
import React, { FC, ReactNode, useState, useEffect } from 'react'
import { useSelector } from '../../../hooks/useSelector'
import { Select, Row, Col } from 'antd'
import { themeColor } from '../../../styles/themeStyles'
import styled from 'styled-components'
import ApproveInput from "../../kyc-backend/ui-elements/Approve"

const CustomSelect = styled(Select)`
    width: 100%;
    &.ant-select .ant-select-selector {
        min-height: 40px;
        .ant-select-selection-search-input {
            height: 38px;
        }
        .ant-select-selection-placeholder, .ant-select-selection-item {
            line-height: 38px;
        }
    }
    &.ant-select-multiple .ant-select-selector {
        .ant-select-selection-search-input {
            height: 32px;
        }
        .ant-select-selection-placeholder, .ant-select-selection-item {
            line-height: 24px;
        }
    }
    &.error {
        box-shadow: 0 0 2px 1px ${themeColor.red};
    }
    &.ant-select-disabled .ant-select-selector {
        background-color: ${themeColor.grayWhite}!important;
        color: ${themeColor.gray}!important;
    }
    &.ant-select-disabled.ant-select-multiple .ant-select-selection-item {
        color: ${themeColor.gray}!important;
    }
    .ant-select-selection-placeholder {
        color: ${themeColor.gray}
    }
`

const ErrorContainer = styled.div`
    color: red;
`


interface SProps {
    label: string,
    id:  string,
    error?: string,
    type: 'authorized-persons' | 'ubos' | 'individual' | 'company',
    placeholder: string,
    mode?: "multiple" | "tags" | undefined,
    onChange: (value: any, addition?: any) => void,
    onSwitchChange?: (value: any) => void,
    children: ReactNode,
    disabled?: boolean,
    defaultValue?: string,
    showErrors?: boolean,
    schemaParent?: string,
    schemaName?: string,
    personId?: string,
    hideApprove?: boolean,
    hideLabel?: boolean
}
const FormSelect: FC<SProps> = ({
    label, id, error, placeholder, mode, onChange, onSwitchChange, children, disabled, defaultValue, showErrors, schemaParent, schemaName, type, personId, hideApprove, hideLabel
}) => {
    const [isError, setIsError] = useState(false);
    const envs = useSelector((state) => state.general.envs);
    useEffect(() => {
        if(error?.length) {
            if(isError !== true) {
                setIsError(true)
            }
        } else {
            if(isError !== false) {
                setIsError(false)
            }
        } 
        
    }, [error]);
    return (
        <>
            {!hideLabel &&
                <label className='ant-form-item-label' htmlFor={id} style={{'width': '100%'}}>
                    <Row justify="space-between">
                        <Col>{label}</Col>
                        {envs.admin && hideApprove!== true &&
                            <ApproveInput onSwitchChange={onSwitchChange} name={id} schemaParent={schemaParent} schemaName={schemaName} type={type} personId={personId} />
                        }
                    </Row>
                </label>
            }
            <CustomSelect
                showSearch
                placeholder={placeholder}
                optionFilterProp="children"
                onChange={onChange}
                id={id}
                mode={mode}
                defaultValue={defaultValue !== "" ? defaultValue :  undefined}
                className={error?.length ? "error" : ""}
                disabled={disabled}
                
              >
                {children}
              </CustomSelect>
            {showErrors !== false &&
                <ErrorContainer>
                    {isError ? (
                        <div>{error}</div>
                    ) : null}
                </ErrorContainer>
            }
          </>
             
    )
}

export default FormSelect
