import { createAsyncThunk } from "@reduxjs/toolkit"
import { api2 } from "api/kyc/api"
import { AsyncThunkActionType } from "../../store"
import { AsyncThunkDataType } from "../../common"
import { Components, Paths } from "api/kyc/generated/client2"

type PathParameters = Paths.Validate.PathParameters | null;
type ExtendedPathParameters = (PathParameters & { step?: number, showErrors?: boolean }) | null;

type ProfileResponse = any;


export const kycApplicationValidate: AsyncThunkActionType<
  ProfileResponse,
  AsyncThunkDataType<ExtendedPathParameters, null, "params">
> = createAsyncThunk("kycApplicationValidate", async (data, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    let response = await api2.validate(data.params, null, data?.config);
    return response.data as ProfileResponse;
  } catch (err: any) {
    return rejectWithValue(err.response.data);
  }
});