import { FC, ReactNode, useState } from 'react'
import { Row, Col, Space, Drawer } from 'antd'
import Text from 'antd/es/typography/Text'
import styled from 'styled-components'
import { useSelector } from 'hooks/useSelector'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { FormCheckboxRadio } from 'modules/kyc-front/ui-elements/FormCheckboxRadio'
import ApproveInput from "modules/kyc-backend/ui-elements/Approve"
import { themeColor } from 'styles/themeStyles'

const CheckboxWrapperStyles = styled.div`
    label {
        margin-bottom: 15px;
        line-height: 20px;
        gap: 25px;
    }
    input {
        position: relative;
        top: 3px;
    }
`
const ErrorContainer = styled.div`
    color: ${themeColor.red};
`

interface CProps {
    error?: string,
    children: ReactNode
}

const CheckboxWrapper: FC<CProps> = ({error, children}) => {
    return (
        <CheckboxWrapperStyles className='checkbox-wrapper'>
            {children}
            {error && error !== '' &&
                <ErrorContainer>{error}</ErrorContainer>
            }
        </CheckboxWrapperStyles>
    )
}

interface KProps {
    errors: any,
    handleInputChange: any,
    setFieldValue: any,
    disabled?: boolean,
    uboType?: boolean,
    type: 'individual' | 'authorized-persons' | 'ubos'
}

const KycDeclarationsForm: FC<KProps> = ({errors, handleInputChange, setFieldValue, disabled, uboType, type}) => {
    const envs = useSelector((state) => state.general.envs);
    const [visible, setVisible] = useState(false);
    const showDrawer = () => {
        setVisible(true);
    };
    const onClose = () => {
        setVisible(false);
    };
    const handleCheckChange = (el: any) => {
        setFieldValue(el.target.name, JSON.parse(el.target.value.toLowerCase()))
        handleInputChange();
    }
    return (
        <Space direction='vertical' size={'large'}>
            <div>
                <Row justify="space-between">
                    <Col>
                        <Text><b>1. Politically Exposed Person</b></Text><QuestionCircleOutlined style={{'marginLeft': '5px'}} onClick={showDrawer} />
                    </Col>
                    <Col>
                        {envs.admin &&
                            <ApproveInput name='isPoliticallyExposedPerson' type={type} />
                        }
                    </Col>
                </Row>
                <Text>Please tick <b>ONE</b> of below options.</Text>
            </div>
            <div>
                <CheckboxWrapper>
                    <FormCheckboxRadio id='isPoliticallyExposedPerson' type='radio' onChange={handleCheckChange} error={errors.isPoliticallyExposedPerson}
                        options={[{
                                label: 'Above Beneficial Owner does not fall within the definition of ‘politically exposed person’ , being natural persons who are or have been entrusted with a prominent public function  within the last year, including their immediate family members or others known to be close associates of such a person, but shall not include middle ranking or more junior officials.',
                                value: false
                            },
                            {
                                label: 'Above Beneficial Owner does fall within the definition of ‘politically exposed person.',
                                value: true
                            }
                        ]
                    } disabled={disabled} showApprove={false} approveType={type} />
                    
                </CheckboxWrapper>
                <Drawer title="Politically Exposed Person" placement="right" onClose={onClose} open={visible}>
                    <p>Prominent public functions being:</p>
                    <p>a) Head of State or of government, ministers, deputy and secretaries of ministers, and high-ranked party officials; </p>
                    <p>b) Members of Parliament and similar legislative functions;</p>
                    <p>c) Members of supreme courts, constitutional courts and other high ranking courts, against which – except in extraordinary cases – there is no appeal;</p> 
                    <p>d) Auditors or directors of Central Banks;</p> 
                    <p>e) Ambassadors, consulates and high ranking officers of the armed forces;</p>
                    <p>f) Members of administrative, management or supervisory bodies of state owned enterprises;</p> 
                    <p>g) Directors, deputy directors and members of senior management and similar officials of international governmental organizations.</p>
                </Drawer>
            </div>
            <div>
                    <Row justify="space-between">
                        <Col>
                            <Text><b>2. US Person</b></Text>
                        </Col>
                        <Col>
                            {envs.admin &&
                                <ApproveInput name='isUSNationality' type={type} />
                            }
                        </Col>
                    </Row>
                    <Text>Please tick <b>ONE</b> of below options.</Text>
                </div>
            <CheckboxWrapper>
                <FormCheckboxRadio id='isUSNationality' type='radio' onChange={handleCheckChange} error={errors.isUSNationality}
                    options={[{
                            label: 'Above Beneficial Owner does not fall within the definition of a ‘US Person’, being a natural person who is citizen or resident of the United States of America, has any other type of mailing address or residence permit (green card) or was born in the United States of America.',
                            value: false
                        },
                        {
                            label: 'Above Beneficial Owner does fall within the definition of a ‘US Person’.',
                            value: true
                        }
                    ]
                } disabled={disabled} showApprove={false} approveType={type} />
            </CheckboxWrapper>
            {uboType &&
                <div>
                    <Text><b>3. Correctness and changes</b></Text><br/>
                    <Text>I hereby declare that the details furnished above are true and correct to the best of my knowledge and belief and <b>I undertake to inform you of any changes therein, immediately by sending an email to KYC@bitclear.li</b>. In case any of the above information is found to be false or untrue or misleading or misrepresenting, I am aware that I may be held liable for it.</Text>
                </div>
            }
        </Space>
        )

}

export default KycDeclarationsForm