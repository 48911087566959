import * as Yup from 'yup'
import { Field, ErrorMessage } from 'formik'
import { FC, useEffect, useState } from 'react'
import { moment } from '../ui-elements/FormDate'
import _ from 'lodash';
import { Row, Col, Divider, Select, Space, Typography } from 'antd'
import { countries } from 'countries-list'
import { Regions } from '../../../utilities/Regions'
import { themeColor } from '../../../styles/themeStyles'
import { useSelector } from '../../../hooks/useSelector'
import { dispatch } from '../../../store/store'
import Title from 'antd/es/typography/Title'
import styled from 'styled-components'
import FormInput from '../ui-elements/FormInput'
import FormSelect from '../ui-elements/FormSelect'
import FormDate from '../ui-elements/FormDate'
import FormUpload from '../ui-elements/FormUpload'
import Well from '../ui-elements/Well'
import { FormCheckboxRadio } from '../ui-elements/FormCheckboxRadio'
import { companyApprovedFieldsGet } from '../../../store/kyc-backend/asyncThunks/companyApprovedFieldsGet'
import BasicFormWrapper from '../ui-elements/BasicForm'
import { useActionStatus } from '../../../hooks/useActionStatus'
import { ErrorElement } from '../ui-elements/ErrorElement'
import { DividerFirst } from '../ui-elements/Dividers'
import { Components } from "../../../api/kyc/generated/client";
const { Paragraph, Text } = Typography

type addressType = Components.Schemas.AddressEntry | undefined;

interface KProps {
    company: any,
    companyDocsGet?: any,
    companyDocsGetParams?: any,
    companyDocsGetClear?: any,
    companyDocArchive?: any,
    companyDocArchiveClear?: any,
    companyDocCreate?: any,
    companyDocCreateClear?: any,
    companyDocUrlGet?: any,
    companyDocUrlGetClear?: any,
    companyGet: any,
    companyUpdate?: any,
    companyUpdatedResp?: any,
    companyClearUpdate?: any,
    printRender?: boolean,
    validationAction?: any,
    validationActionClear?: any
}


const KycCompanyInfoForm: FC<KProps> = ({printRender, company, companyDocsGet, companyDocsGetClear, companyDocsGetParams, companyDocArchive, companyDocArchiveClear, companyDocCreate, companyDocCreateClear, companyDocUrlGet, companyDocUrlGetClear, companyGet, companyUpdate, companyUpdatedResp, companyClearUpdate, validationAction, validationActionClear}) => {
	const { Option } = Select;
    const [errorsLoaded, setErrorsLoaded] = useState(false);
    const envs = useSelector((state) => state.general.envs);
    const nav = useSelector((state) => state.general.nav);
    const companyProfile = company;
    const description = company;
    const status = useSelector((state) => envs.admin ? state.admin.backStatus : state.user.status) 
    const companyDocs = useSelector((state) => envs.admin ? state.admin.backCompanyDocs.companyDocs : state.user.companyDocs.companyDocs)
    const newDoc = useSelector((state) => envs.admin ? state.admin.backCompanyDocs.newCompanyDoc : state.user.companyDocs.newCompanyDoc)
    const docUrlResp = useSelector((state) => envs.admin ? state.admin.backCompanyDocs.companyDocUrl : state.user.companyDocs.companyDocUrl)
    const companyDocArchived = useSelector((state) => envs.admin ? state.admin.backCompanyDocs.companyDocArchived : state.user.companyDocs.companyDocArchived)
    const registeredAddress: addressType = companyProfile?.registeredAddress;
    const headOfficeAddress: addressType = companyProfile?.headOfficeAddress;
    const initialValues = { 
        name: companyProfile?.name, 
        legalForm: companyProfile?.legalForm, 
        registeredAddress: {
            address: registeredAddress?.address ?? '',
            postcode: registeredAddress?.postcode ?? '', 
            city: registeredAddress?.city, 
            country: registeredAddress?.country
        },
        headOfficeAddress: {
            headOfficeAddress: (headOfficeAddress?.address === "" &&  headOfficeAddress?.postcode === "" && headOfficeAddress?.city === "" && headOfficeAddress?.country === "") ? 'same' : 'other',
            address: headOfficeAddress?.address,
            postcode: headOfficeAddress?.postcode, 
            city: headOfficeAddress?.city, 
            country: headOfficeAddress?.country
        },
        dateOfIncorporation: companyProfile?.dateOfIncorporation || undefined, 
        registrationNumber: companyProfile?.registrationNumber, 
        placeOfIncorporation: companyProfile?.placeOfIncorporation, 
        businessDescription: {
            website: description?.website, 
            activitiesAndBusinessModel: description?.activitiesAndBusinessModel, 
            businessLicence: description?.businessLicence,
            annualTurnoverEUR: description?.annualTurnoverEUR,
            estimatedAmountOfEUR: description?.estimatedAmountOfEUR, 
            geographicalArea: description?.geographicalArea,
            fundingOfBusinessType: description?.fundingOfBusinessType, 
            fundingOfBusinessDescription: description?.fundingOfBusinessDescription,
            numberOfEmployees: description?.numberOfEmployees?.toString()
        }
    }
    const validation = Yup.object({
        name: Yup.string(),
        legalForm: Yup.string(),
        registeredAddress: Yup.object({
            address: Yup.string(),
            postcode: Yup.string(),
            city: Yup.string(),
            country: Yup.string()
        }),
        dateOfIncorporation: Yup.string(),
        registrationNumber: Yup.string(),
        placeOfIncorporation: Yup.string(),
        businessDescription: Yup.object({
            website: Yup.string(),
            activitiesAndBusinessModel: Yup.string(),
            businessLicence: Yup.string(),
            geographicalArea: Yup.string(),
            numberOfEmployees: Yup.string(),
            annualTurnoverEUR: Yup.string(),
            estimatedAmountOfEUR: Yup.string(),
            fundingOfBusinessType: Yup.string(),
            fundingOfBusinessDescription: Yup.string()
        }),
        headOfficeAddress: Yup.object({
            headOfficeAddress: Yup.string(),
            address: Yup.string(),
            postcode: Yup.string(),
            city: Yup.string(),
            country: Yup.string()
        })
    });
    useEffect(() => {
        if(envs.admin) {
            dispatch(companyApprovedFieldsGet({params: {companyId: envs.profileId}}))
        }
    }, []);
   
    const countriesAndRegions = {...Regions, ...countries};
	return (
        <BasicFormWrapper 
            id='companyInfoForm' 
            initial={initialValues} 
            validation={validation} 
            dispatchSubmit={companyUpdate} 
            params={{companyId: envs.profileId}}  
            dispatchClear={companyClearUpdate} 
            dispatchGet={companyGet} 
            successCondition={companyUpdatedResp?.companyId} 
            printRender={printRender} 
            validatedElem={status.companyValidated} 
            validationAction={validationAction} 
        >
            {({handleInputChange, onSelectChange, onDateChange, values, errors, setFieldValue, disabled, initialErrorsValues}) => {
                const onAddressTypeChange = (el: any) => {
                    handleInputChange(el);
                    if(el.target.value === 'same') {
                        setFieldValue('headOfficeAddress.address', '');
                        setFieldValue('headOfficeAddress.postcode', '');
                        setFieldValue('headOfficeAddress.city', '');
                        setFieldValue('headOfficeAddress.country', '');
                    }
                }
                return (
                    <>
                        <DividerFirst />
                        <Title level={3}>Basic Company Information</Title>
                        
                        <FormInput label='Name of legal entity *' name='name' schemaParent='profile' error={errors.name} onChange={handleInputChange} disabled={disabled} printRender={printRender} value={values.name} type='company'  />
                        <FormInput label='Legal form *' name='legalForm' schemaParent='profile' error={errors.legalForm} onChange={handleInputChange} disabled={disabled} printRender={printRender} value={values.legalForm} type='company' />
                        <Title level={4} className='title4'>Registered address</Title>
                        <FormInput label='Address *' name='registeredAddress.address' error={errors.registeredAddress?.address} onChange={handleInputChange} disabled={disabled} printRender={printRender} value={values.registeredAddress.address} type='company' />
                        <Row gutter={30}>
                           <Col span={12}>
                               <FormInput label='Postcode *' name='registeredAddress.postcode' error={errors.registeredAddress?.postcode} onChange={handleInputChange} disabled={disabled} printRender={printRender} value={values.registeredAddress.postcode} type='company' />
                           </Col>
                           <Col span={12}>
                               <FormInput label='City *' name='registeredAddress.city' error={errors.registeredAddress?.city} onChange={handleInputChange} disabled={disabled} printRender={printRender} value={values.registeredAddress.city} type='company' />
                           </Col>
                        </Row>
                        <Row gutter={30}>
                            <Col span={12}>
                                <FormSelect defaultValue={values.registeredAddress.country} label='Country' id='registeredAddress.country' error={errors.registeredAddress?.country} placeholder='Select country' onChange={(val) => onSelectChange(val, 'registeredAddress.country')} disabled={disabled} type='company' >
                                    {Object.keys(countries).map((val, i) => {
                                        const country = countries[val as keyof typeof countries]
                                        return <Option key={i} value={val}>{country.name}</Option>
                                    })}
                                </FormSelect>
                            </Col>
                        </Row>
                        <Row gutter={30}>
                           <Col span={12}>
                               <FormDate label='Date of incorporation *' id='dateOfIncorporation' schemaParent='profile'  error={errors.dateOfIncorporation} defaultValue={values.dateOfIncorporation ? moment(values.dateOfIncorporation, 'YYYY-MM-DD') : undefined} onChange={(val) => onDateChange(val, 'dateOfIncorporation')} disabled={disabled}  type='company' />
                           </Col>
                           <Col span={12}>
                               <FormInput label='Registration number *' name='registrationNumber' schemaParent='profile'  error={errors.registrationNumber} onChange={handleInputChange} disabled={disabled} printRender={printRender} value={values.registrationNumber} type='company' />
                           </Col>
                        </Row>
                        <Row gutter={30}>
                           <Col span={12}>
                               <FormInput label='Place of incorporation *' name='placeOfIncorporation' schemaParent='profile' error={errors.placeOfIncorporation} onChange={handleInputChange} disabled={disabled} printRender={printRender} value={values.placeOfIncorporation} type='company' />
                           </Col>
                        </Row>
                        <Title level={4} className='title4' style={printRender ? values.headOfficeAddress.headOfficeAddress === 'other' ? {'marginTop': '-5px', 'marginBottom': '-5px'} : {'marginBottom': '-5px'} : {'marginBottom': '-25px'}}>Address of head office</Title>
                        <Row className={values.headOfficeAddress.headOfficeAddress === 'other' ? 'hide-print' : ''}>
                            <Col span={13}>
                                <FormCheckboxRadio id='headOfficeAddress.headOfficeAddress' error={errors.headOfficeAddress?.headOfficeAddress} type='radio' onChange={onAddressTypeChange} 
                                    options={[{
                                            label: 'same as registered address',
                                            value: 'same'
                                        },
                                        {
                                            label: 'other',
                                            value: 'other'
                                        }
                                    ]
                                } disabled={disabled} showAdditionalApprove={false} approveType='company' />
                            </Col>
                        </Row>
                        {values.headOfficeAddress.headOfficeAddress === 'other' || envs.admin ? (
                            <Well className='well'>
                                <Space size={15} direction='vertical' style={printRender ? {'marginTop' :'-10px'} : {}}>
                                    <FormInput label='Street and number *' name='headOfficeAddress.address' error={errors.headOfficeAddress?.address} onChange={handleInputChange} disabled={disabled} printRender={printRender} value={values.headOfficeAddress.address} type='company' placeholder={values.headOfficeAddress.headOfficeAddress === 'same' ? values.registeredAddress.address : ''} />
                                    <Row gutter={30}>
                                           <Col span={12}>
                                               <FormInput label='Postcode *' name='headOfficeAddress.postcode' error={errors.headOfficeAddress?.postcode} onChange={handleInputChange} disabled={disabled} printRender={printRender} value={values.headOfficeAddress.postcode} type='company' placeholder={values.headOfficeAddress.headOfficeAddress === 'same' ? values.registeredAddress.postcode : ''} />
                                           </Col>
                                           <Col span={12}>
                                               <FormInput label='City *' name='headOfficeAddress.city' error={errors.headOfficeAddress?.city} onChange={handleInputChange} disabled={disabled} printRender={printRender} value={values.headOfficeAddress.city} type='company' placeholder={values.headOfficeAddress.headOfficeAddress === 'same' ? values.registeredAddress.city : ''}  />
                                           </Col>
                                       </Row>
                                    <Row gutter={30} style={printRender ? {'marginBottom' :'-10px'} : {}}>
                                        <Col span={12}>
                                            {/*@ts-ignore*/}
                                            <FormSelect label='Country *' defaultValue={values.headOfficeAddress.country}  id='headOfficeAddress.country' error={errors.headOfficeAddress?.country} onChange={(val) => onSelectChange(val, 'headOfficeAddress.country')} disabled={disabled} type='company' placeholder={values.headOfficeAddress.headOfficeAddress === 'same' ? values.registeredAddress.country ?  countries[values.registeredAddress.country].name : '' : 'Select country'}  >
                                                {Object.keys(countries).map((val, i) => {
                                                    const country = countries[val as keyof typeof countries]
                                                    return <Option key={i} value={val}>{country.name}</Option>
                                                })}
                                            </FormSelect>
                                        </Col>
                                    </Row>
                                </Space>
                            </Well>
                        ) : (<></>)}
                   <Divider />
                   <Title className={values.headOfficeAddress.headOfficeAddress === 'same' ? 'companyMarginDescription' : 'companyAddressMarginDescription'} level={3}>Business description</Title>
                   <FormInput label='Corporate website *' name='businessDescription.website' error={errors.businessDescription?.website} onChange={handleInputChange} disabled={disabled} printRender={printRender} value={values.businessDescription.website} type='company' />
                   
                   <Row gutter={30}>
                        <Col span={24}>
                            <FormSelect mode="multiple" defaultValue={values.businessDescription.geographicalArea !== '' ? values.businessDescription.geographicalArea?.split(',') : values.businessDescription.geographicalArea} label='Main countries and regions of geographical area of operation *' id='businessDescription.geographicalArea' error={errors.geographicalArea} placeholder='Select at least one country' onChange={(val) => onSelectChange(val, 'businessDescription.geographicalArea')} disabled={disabled} type='company' >
                                {Object.keys(countriesAndRegions).map((val, i) => {
                                    const country = countriesAndRegions[val as keyof typeof countriesAndRegions]
                                    return <Option key={i} value={val}>{country.name}</Option>
                                })}
                            </FormSelect>
                        </Col>
                    </Row>
                     <Row>
                        <Col span={13}>
                            <FormCheckboxRadio className={values.headOfficeAddress.headOfficeAddress === 'same' ? '' : 'checkboxCompanyAddress'} label='Number of direct employees' id='businessDescription.numberOfEmployees' error={errors.businessDescription?.numberOfEmployees} type='radio' onChange={handleInputChange}
                                options={[{
                                        label: 'None',
                                        value: '0'
                                    },
                                    {
                                        label: '1 - 10',
                                        value: '1'
                                    },
                                    {
                                        label: '11 - 100',
                                        value: '11'
                                    },
                                    {
                                        label: '> 100',
                                        value: '101'
                                    }
                                ]
                            } disabled={disabled} approveType='company' />
                        </Col>
                    </Row>
                   <FormInput label='Nature of business activities and business model *' subLabel='(please explain in 2-3 sentences)' subLabelClass={values.headOfficeAddress.headOfficeAddress === 'other' ? '' : 'companySubLabelMargin1'}  name='businessDescription.activitiesAndBusinessModel' id="activitiesAndBusinessModelTextarea" error={errors.activitiesAndBusinessModel} component='textarea' onChange={handleInputChange} disabled={disabled} value={values.businessDescription.activitiesAndBusinessModel} printRender={printRender} type='company' />
                   <FormInput className={values.headOfficeAddress.headOfficeAddress === 'other' ? 'textarea-small-print' : ''} label='Does the company have any business license or is supervised by a financial markets regulator? *' subLabel='(please name authority and add a copy of license or link to authority website 
                    where license can be looked up)' subLabelClass={values.headOfficeAddress.headOfficeAddress === 'other' ? '' : 'companySubLabelMargin2'}  name='businessDescription.businessLicence' id="businessLicenceTextarea" error={errors.businessLicence} component='textarea' onChange={handleInputChange} disabled={disabled} value={values.businessDescription.businessLicence} printRender={printRender} type='company' />
                    <Row gutter={30}>
                        <Col span={13}>
                            <FormInput label='Approximate annual turnover (EUR) *' name='businessDescription.annualTurnoverEUR' error={errors.annualTurnoverEUR} onChange={handleInputChange} disabled={disabled} printRender={printRender} value={values.businessDescription.annualTurnoverEUR} type='company' formatting='numbers' />
                        </Col>
                    </Row>
                    <label>Estimated amount of future exchange transactions settled through Bitclear AG per year:</label>
                    <Row gutter={30}>
                        <Col span={13}>
                            <FormInput label='Amount (EUR) *' name='businessDescription.estimatedAmountOfEUR' error={errors.estimatedAmountOfEUR} onChange={handleInputChange} disabled={disabled} printRender={printRender} value={values.businessDescription.estimatedAmountOfEUR} type='company' formatting='numbers' />
                        </Col>
                    </Row>
                    
                    <FormCheckboxRadio className={values.headOfficeAddress.headOfficeAddress === 'same' ? '' : 'checkboxCompanyAddress'} label='Funding of business activities' id='businessDescription.fundingOfBusinessType' error={errors.businessDescription?.fundingOfBusinessType} type='radio' onChange={handleInputChange}
                        options={[{
                                label: 'equity/debt contribution by UBO',
                                value: 'EquityDebtContributionByUBO'
                            },
                            {
                                label: 'other',
                                value: 'OtherFundingOfBusinessType'
                            }
                        ]
                    } disabled={disabled} approveType='company' />
                    <Well className='well'>
                        <FormInput label='Please explain' name='businessDescription.fundingOfBusinessDescription' error={errors.businessDescription?.fundingOfBusinessDescription} id="fundingOfBusinessDescriptionTextarea" component='textarea' onChange={handleInputChange} disabled={disabled} value={values.businessDescription.fundingOfBusinessDescription} printRender={printRender} type='company' />
                    </Well>
                    <div className='hide-print' style={{'marginBottom': '-10px'}}>
                        <Title level={4}>Other documents</Title>
                        <span>(additional company documents specifically requested by Bitclear)</span>
                    </div>
                    <FormUpload name='Other documents' disabled={disabled} /*docs={companyDocs[0]}*/ docType='OtherDocumentKind' uploadAdmin={true} isErrorMessage={errors.personalDetails?.OtherDocumentKind} personId={envs.profileId} personType={'company'} /*newDoc={newDoc} docCreate={companyDocCreate} docClear={companyDocCreateClear} docsGet={companyDocsGet} docGetClear={companyDocsGetClear} docsGetParams={companyDocsGetParams} docUrlGet={companyDocUrlGet} docUrlClear={companyDocUrlGetClear} docUrlResp={docUrlResp} docArchived={companyDocArchived} docDelete={companyDocArchive} docDeleteClear={companyDocArchiveClear}*/ type={'company'} maxSize={envs.admin ? 15 : 8} />
                    <div className={values.headOfficeAddress.headOfficeAddress === 'same' ? 'companyMargin' : ''}></div>
                    </>
                )
            }}

        </BasicFormWrapper>
	)
}

export default KycCompanyInfoForm
