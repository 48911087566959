import { FC, useState } from 'react'
import { Row, Col, Select, Space, Drawer } from 'antd'
import Title from 'antd/es/typography/Title'
import { QuestionCircleOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import { countries } from 'countries-list'
import FormInput from 'modules/kyc-front/ui-elements/FormInput'
import FormSelect from 'modules/kyc-front/ui-elements/FormSelect'
import FormUpload from 'modules/kyc-front/ui-elements/FormUpload'
import { KycAddressInfo } from 'modules/kyc-front/KycDocsInfo'

export interface KProps {
    id?: string,
    person: any,
    type: 'ap' | 'ubo' | 'company' | 'individual',
    personType: 'ubos' | 'authorized-persons' | 'individual' | 'company',
    values: any,
    errors: any,
    handleInputChange: any
    onSelectChange: any,
    onDateChange: any,
    disabled?: boolean,
    printRender?: boolean,
    section: any
}

const TooltipHolder = styled.span`
    position: relative;
    bottom: 2px;
    font-size: 14px;
    margin-bottom: 2px;
    .anticon {
        margin-left: 5px;
    }
`

const KycContactForm: FC<KProps> = ({id, person, type, personType, values, errors, handleInputChange, onSelectChange, disabled, printRender}) => {
        const { Option } = Select
        const [visible, setVisible] = useState(false)
        const showDrawer = () => {
            setVisible(true)
        };
        const onClose = () => {
            setVisible(false)
        };
        const personIsArchived = person?.archivedAt ? true : false
        return (
            <Space direction='vertical' size={'large'}>
                <FormInput label='Residential address *' name='residentialAddress.address' error={errors.residentialAddress?.address} onChange={handleInputChange} disabled={disabled} printRender={printRender} value={values.residentialAddress?.address} personId={id} type={personType} showApprove={!personIsArchived} />
                <Row gutter={30}>
                   <Col span={12}>
                       <FormInput label='Postcode *' name='residentialAddress.postcode' error={errors.residentialAddress?.postcode} onChange={handleInputChange} disabled={disabled} printRender={printRender} value={values.residentialAddress?.postcode} schemaParent='residentialAddress' schemaName='postcode' personId={id} type={personType} showApprove={!personIsArchived} />
                   </Col>
                   <Col span={12}>
                       <FormInput label='City *' name='residentialAddress.city' error={errors.residentialAddress?.city} onChange={handleInputChange} disabled={disabled} printRender={printRender} value={values.residentialAddress?.city} schemaParent='residentialAddress' schemaName='city' personId={id} type={personType} showApprove={!personIsArchived} />
                   </Col>
                </Row>
                <Row gutter={30}>
                    <Col span={24}>
                        <FormSelect defaultValue={values.residentialAddress?.country} label='Residence country *' id='residentialAddress.country' error={errors.residentialAddress?.country} placeholder='Select country' onChange={(val) => onSelectChange(val, 'residentialAddress.country')} disabled={disabled} schemaParent='residentialAddress' schemaName='country' personId={id} type={personType} hideApprove={personIsArchived} >
                            {Object.keys(countries).map((val, i) => {
                                const country = countries[val as keyof typeof countries]
                                return <Option key={'residence' + i} value={val}>{country.name}</Option>
                            })}
                        </FormSelect>
                    </Col>
                </Row>
                <Row gutter={30}>
                   <Col span={12}>
                       <FormInput label='Email address *' name='email' error={errors.email} onChange={handleInputChange} disabled={disabled} printRender={printRender} value={values.email} type={personType} personId={id} showApprove={!personIsArchived} />
                   </Col>
                   <Col span={12}>
                       <FormInput label='Mobile number (optional)' name='phone' error={errors.phone} onChange={handleInputChange} disabled={disabled} printRender={printRender} value={values.phone} type={personType} personId={id} showApprove={!personIsArchived} />
                   </Col>
                </Row>
                <div className='hide-print' style={{'marginBottom': '-10px'}}>
                    <Title level={4}>Proof of address<TooltipHolder className='hide-print'><QuestionCircleOutlined onClick={showDrawer} /></TooltipHolder></Title>
                    <span>(recent and not older than 12 months)</span>
                </div>
                <Drawer rootClassName='hide-print' title="Proof of address" placement="right" onClose={onClose} open={visible}>
                    <KycAddressInfo />
                </Drawer>
                <FormUpload name='Proof of address' disabled={disabled} docType='ProofOfAddress' uploadAdmin={true} isErrorMessage={errors.docs?.ProofOfAddress} personId={id} personType={personType} type={type} hideApprove={personIsArchived} />
            </Space>
        )

}

export default KycContactForm