import { ItemRender } from 'antd/es/upload/interface'
import React, { FC, useEffect, useState, useRef } from 'react'
import { Button, Upload, message, Space, Row, Col, Tooltip } from 'antd'
import type { UploadFile, UploadProps } from 'antd/es/upload/interface';
import download from 'downloadjs'
import { DownOutlined, InboxOutlined, UploadOutlined, DownloadOutlined, SyncOutlined, ArrowUpOutlined } from '@ant-design/icons'
import { useSelector } from '../../../hooks/useSelector'
import { dispatch } from '../../../store/store'
import { readyForReviewValidate } from '../../../store/kyc-front/asyncThunks/readyForReviewValidate'
import { individualReadyForReviewValidate } from '../../../store/kyc-front/asyncThunks/individualReadyForReviewValidate'
import { readyForReviewBackValidate } from '../../../store/kyc-backend/asyncThunks/readyForReviewBackValidate'
import { individualBackReadyForReviewValidate } from '../../../store/kyc-backend/asyncThunks/individualBackReadyForReviewValidate'
import { authorizedPersonDocUrlBackGet } from '../../../store/kyc-backend/asyncThunks/authorizedPersonDocUrlBackGet'
import { companyDocUrlGet } from '../../../store/kyc-backend/asyncThunks/companyDocUrlGet'
import { companyDocUrlGetClear } from '../../../store/kyc-backend/slices/companyDocsSlice'
import { uboBackDocUrlGet } from '../../../store/kyc-backend/asyncThunks/uboBackDocUrlGet'
import { uboBackDocUrlGetClear } from '../../../store/kyc-backend/slices/uboDocsSlice'
import { individualBackDocUrlGet } from '../../../store/kyc-backend/asyncThunks/individualBackDocUrlGet'
import { individualBackDocUrlGetClear } from '../../../store/kyc-backend/slices/individualDocsSlice'
import { authorizedPersonDocUrlBackGetClear } from '../../../store/kyc-backend/slices/authorizedPersonDocsSlice'
import { themeColor } from '../../../styles/themeStyles'
import { camelCaseToName } from '../../../utilities/nameParsers'
import styled from 'styled-components'
import { Components } from '../../../api/kyc/generated/client'
import { useActionStatus } from '../../../hooks/useActionStatus'
import { ButtonUpper } from '../ui-elements/Buttons'
import { api } from "../../../api/kyc/api";
import ApproveInputOld from "../../kyc-backend/ui-elements/ApproveOld"

const DraggerContainer = styled.div`
    > span:first-child {
        display: flex;
        flex-direction: column-reverse;
    }
    .ant-upload-list {
        border: 1px solid ${themeColor.grayBasic};
        border-top: 0;
        margin-bottom: 30px;
        padding: 0 15px 0 15px;
    }
    &.empty .ant-upload-list {
        border: 0;
    }
    &.dragger-disabled-body {
        .ant-upload-list-item-name {
            color: ${themeColor.gray}
        }
        .anticon-delete {
            display: none;
        }
    }
    .ant-upload-list-item-container {
        .ant-upload-list-item {
            cursor: pointer;
            padding: 15px 0;
            border-bottom: 1px solid ${themeColor.grayBasic};
            margin-top: 0;
            height: initial;
            &:hover {
                color: ${themeColor.primaryColor}
            }
        }

        &:last-child {
            .ant-upload-list-item {
                border-bottom: 0;
            }

        }
    }
    .ant-upload-span {
        height: auto;
    }
    .ant-upload-list-item-action {
        position: absolute;
        right: 5px;
        top: 15px;
        &:hover, &:focus, &:active {
            background-color: transparent;
        }
    }
    .ant-upload.error {
        box-shadow: 0 0 2px 1px ${themeColor.red};
    }
    .ant-upload-list-item-name {
        max-width: 60%;
        max-width: calc(100% - 115px);
    }
    .ant-upload-list-text .ant-upload-list-item-card-actions, .ant-upload-list-picture .ant-upload-list-item-card-actions {
        margin-left: 50px;
    }
    .restore-icon {
        position: relative;
        top: -37px;
        left: 80%;
        left: calc(100% - 25px);
        height: 0;
        .anticon {
            cursor: pointer;
        }
    }
    .ant-upload.ant-upload-drag .ant-upload-drag-icon .anticon {
        color: ${themeColor.primaryColor}
    }
    overflow: hidden;
`

const ErrorContainer = styled.div`
    color: ${themeColor.red};
`

const DraggerHeader = styled.h4`
    border: 1px solid ${themeColor.grayBasic};
    border-bottom: 0;
    margin-bottom: -1px;
    text-align: center;
    padding: 15px 20px 0;
    div {
        padding-bottom: 15px;
        border-bottom: 1px solid ${themeColor.grayBasic};
    }

`
const NameDiv = styled.div`
    //display: flex;
    //justify-content: space-between;
    .name-label {
        
    }
    .name-person {
        width: 120px;
    }
    .name-filename {
        width: 140px;
    }
`
type documentKind = Components.Schemas.DocumentKind;

interface FProps {
    docs?: any,
    adminOnly?: boolean,
    docType: string | Blob,
    personId?: string,
    personType?: 'uboId' | 'apId' | 'individualId' | 'companyId', 
    type: 'ap' | 'ubo' | 'individual' | 'company',
    newDoc?: any,
    docCreate?: any,
    docClear?: any,
    docGetClear?: any,
    docsGet?: any,
    docsGetParams?: any,
    docUrlGet?: any,
    docUrlClear?: any,
    docUrlResp?: any,
    docDelete?: any,
    docDeleteClear? : any,
    name: string,
    maxCount?: number,
    disabled?: boolean,
    docArchived?: any,
    onSwitchChange?: (value: any) => void,
    isErrorMessage?: boolean,
    schemaParent?: string,
    schemaName?: string,
    hideApprove?: boolean,
    uploadAdmin?: boolean,
    businessRelation?: boolean,
    maxSize?: number
}

const FormUpload: FC<FProps> = ({docs, adminOnly, docType, personId, personType, newDoc, docCreate, docClear, docsGet, docGetClear, docUrlGet, docUrlClear, docUrlResp, docDelete, docDeleteClear, docsGetParams, name, maxCount, disabled, docArchived, onSwitchChange, isErrorMessage, schemaParent, schemaName, type, hideApprove, uploadAdmin, businessRelation, maxSize}) => {
    const [fileEl, setFileEl] = useState();
    const [restore, setRestore] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [listingIsSet, setListing] = useState(false);
    const [fileList, setFileList] = useState<any[]>([]);
    const [archivedFileList, setArchivedFileList] = useState<any[]>([]);
    const [downloadClicked, setDownloadClicked] = useState(false);
    const [downloadName, setDownloadName] = useState('');
    const [messageShown, setMessageShown] = useState(true);
    const [docDeletedId, setDocDeletedId] = useState('');
    const [showArchived, setShowArchived] = useState(false);
    const { Dragger } = Upload;
    const envs = useSelector((state) => state.general.envs);
    const { isPending, isError, isFullfilled, isCleared } = useActionStatus(docCreate);
    const archivingStatus = useActionStatus(docDelete);
    const gettingStatus = useActionStatus(docsGet);
    const backAuthorizedPersonDocs = useSelector((state) => state.admin.backAuthorizedPersonDocs);
    const backUboDocs = useSelector((state) => state.admin.backUboDocs);
    const backCompanyDocs = useSelector((state) => state.admin.backCompanyDocs);
    const backIndividualDocs = useSelector((state) => state.admin.backIndividualDocs);
    const elel = useSelector((state) => state.general.responseInfo);
    useEffect(() => {

        if(docsGet) {
            dispatch(docsGet({ params: docsGetParams }));
        }
        return () => {
            setListing(false);
        }
    }, []);              
    useEffect(() => {
        if(messageShown) {
            if(newDoc) {
                //@ts-ignore
                if(newDoc.role === docType && (personType && (newDoc[personType] === personId))) {
                    if(isFullfilled && (newDoc.id !== '')) {
                       message.success('File was sucessfully added');
                        if(envs.accountType === 'BUSINESS') {
                            if(envs.admin) {
                                dispatch(readyForReviewBackValidate({ params: { companyId: envs.profileId} }));
                            } else {
                                dispatch(readyForReviewValidate({ params: { companyId: envs.profileId} }));
                            }
                        } else{
                            if(envs.admin) {
                                dispatch(individualBackReadyForReviewValidate({params: {individualId: envs.profileId}}))
                            } else {
                                dispatch(individualReadyForReviewValidate({params: {individualId: envs.profileId}}));
                            }
                        }
                       setFileEl(undefined);
                       dispatch(docClear({}));
                       dispatch(docsGet({ params: docsGetParams }))
                       setMessageShown(false);
                       setUploading(false)
                    }
                }
                //@ts-ignore
                if(isError && newDoc[personType] === personId && newDoc.fileType === docType) {
                        if(newDoc.status === 413) {
                            message.error(`File size is too large. Try uploading file smaller than ${maxSize ? maxSize : 8}MB`);
                        } else {
                            message.error('Some error occured while uploading this file');
                        }
                        setFileEl(undefined);
                        dispatch(docClear({}))
                        setMessageShown(false);
                        setUploading(false);
                }
                    
            }
            
        }
    }, [newDoc]);
    useEffect(() => {
        if(messageShown) {
            if(archivingStatus.isFullfilled && docArchived.id && docArchived.id === docDeletedId) {
                if(restore) {
                    message.success('File was sucessfully restored');
                    setRestore(false);
                } else {
                    message.success('File was sucessfully deleted');
                }
                if(envs.accountType === 'BUSINESS') {
                    if(envs.admin) {
                        dispatch(readyForReviewBackValidate({ params: { companyId: envs.profileId} }));
                    } else {
                        dispatch(readyForReviewValidate({ params: { companyId: envs.profileId} }));
                    }
                } else{
                    if(envs.admin) {
                        dispatch(individualBackReadyForReviewValidate({params: {individualId: envs.profileId}}))
                    } else {
                        dispatch(individualReadyForReviewValidate({params: {individualId: envs.profileId}}));
                    }
                }
                dispatch(docDeleteClear({}));
                dispatch(docsGet({ params: docsGetParams }))
                setMessageShown(false);
                setUploading(false);
                setDocDeletedId('');
            }
            if(archivingStatus.isError && docArchived.id && docArchived.id === docDeletedId) {
                message.error('Some error occured while deleting this file');
                dispatch(docDeleteClear({}));
                setMessageShown(false);
                setUploading(false);
                setDocDeletedId('');
            }
      }
    }, [docArchived]);
    useEffect(() => {
        if(docs) {
            if(listingIsSet === false && gettingStatus.isFullfilled && gettingStatus.isCleared !== true) {
                let els;
                let archivedEls;
                if(docType === 'any') {
                    els = docs?.filter((el: any) => !el.archivedAt);
                    archivedEls = docs?.filter((el: any) => el.archivedAt);
                } else {
                    if(docs?.value) {
                        els = docs?.value.filter((el: any) => el.role === docType && !el.archivedAt);
                        archivedEls = docs?.value.filter((el: any) => el.role === docType && el.archivedAt);
                    } else {
                        els = docs?.filter((el: any) => el.role === docType && !el.archivedAt);
                        archivedEls = docs?.filter((el: any) => el.role === docType && el.archivedAt);
                    }
                }
                let files:any[] = [];
                let archivedFiles:any[] = [];
                els.forEach((el: any) => {
                    files.push({
                      uid: el.id ? el.id : el.documentId,
                      elType: el.beneficialOwnerId ? 'ubo' : el.authorizedPersonId ? 'ap' : el.companyId ? 'company' : 'individual',
                      userId: el.beneficialOwnerId ? el.beneficialOwnerId : el.authorizedPersonId ? el.authorizedPersonId : '',
                      //url: '/',
                      originalName: el.originalFilename,
                      name: docType === 'any' ? <NameDiv>
                        <div className='name-label'><b>{camelCaseToName(el.role)}{el.beneficialOwnerId ? ' - ' + el.beneficialOwnerId : el.authorizedPersonId ? ' - ' + el.authorizedPersonId : ''}</b></div>
                        <div className='name-filename'>{el.originalFilename}</div>
                    </NameDiv> : el.originalFilename,
                      status: 'done'
                    })
                });
                archivedEls.forEach((el: any) => {
                    archivedFiles.push({
                      uid: el.id ? el.id : el.documentId,
                      elType: el.beneficialOwnerId ? 'ubo' : el.authorizedPersonId ? 'ap' : el.companyId ? 'company' : 'individual',
                      userId: el.beneficialOwnerId ? el.beneficialOwnerId : el.authorizedPersonId ? el.authorizedPersonId : '',
                      //url: '/',
                      originalName: el.originalFilename,
                      name: docType === 'any' ? <NameDiv>
                        <div className='name-label'><b>{camelCaseToName(el.role)}{el.beneficialOwnerId ? ' - ' + el.beneficialOwnerId : el.authorizedPersonId ? ' - ' + el.authorizedPersonId : ''}</b></div>
                        <div className='name-filename'>{el.originalFilename}</div>
                    </NameDiv> : el.originalFilename,
                      status: 'done'
                    })
                });
                setListing(true);
                setFileList(files);
                setArchivedFileList(archivedFiles);
                //dispatch(docGetClear({}));
                //here clear docsGet or not?
            }
        }
        
        
    }, [docs]);
    useEffect(() => {   
        if(docUrlResp && downloadClicked) {
            handleDownload(docUrlResp, docUrlClear);
        }
    }, [docUrlResp]);

    useEffect(() => {   
        let docElem = backAuthorizedPersonDocs.authorizedPersonDocUrl[0];
        if(docElem && downloadClicked) {
            handleDownload(docElem, authorizedPersonDocUrlBackGetClear);
        }
    }, [backAuthorizedPersonDocs.authorizedPersonDocUrl]);

    useEffect(() => {   
        let docElem = backUboDocs.uboDocUrl[0];
        if(docElem && downloadClicked) {
            handleDownload(docElem, uboBackDocUrlGetClear);
        }
    }, [backUboDocs.uboDocUrl]);

    useEffect(() => {   
        let docElem = backCompanyDocs.companyDocUrl[0];
        if(docElem && downloadClicked && !businessRelation) {
            handleDownload(docElem, companyDocUrlGetClear);
        }
    }, [backCompanyDocs.companyDocUrl]);

    useEffect(() => {   
        let docElem = backIndividualDocs.individualDocUrl;
        if(docElem && downloadClicked && !businessRelation) {
            handleDownload(docElem, individualBackDocUrlGetClear);
        }
    }, [backIndividualDocs.individualDocUrl]);

    const handleDownload = (docUrlResp: any, clearAction: any) => {
        let val = docUrlResp.value ? docUrlResp.value : docUrlResp;
        if(val && val !== '') {
            const url = window.URL.createObjectURL(new Blob([val]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', downloadName);
            document.body.appendChild(link);
            link.click();
            dispatch(clearAction({}));
            setDownloadClicked(false);
            setDownloadName('');
        }
    }
   
    const handleUpload = (file: any) => {
        setUploading(true);
        setMessageShown(true);
        const formData = new FormData();
        formData.append('fileType', docType);
        formData.append('file', file.file);
        if(adminOnly) {
            /*@ts-ignore*/
            formData.append('adminOnly', adminOnly);
        }
        //@ts-ignore
        dispatch(docCreate({ params: docsGetParams, data: formData, config: {'fileType': docType, [personType]: personId }} ))
    };
    const handleDelete = (file: any) => {
    };
    const handleShowArchived = () => {
        setShowArchived(!showArchived);
    }
    const handleRestoreClick = (el: any) => {
        setRestore(true);
        setUploading(true);
        dispatch(docDelete({ params: {...docsGetParams, documentId: el.uid}, data: {archive: false} }))
        setMessageShown(true);
        setDocDeletedId(el.uid);
        setListing(false);
    }
    const uploadProps = {
        multiple: true,
        showUploadList: {
            showDeleteIcon: true,
            showPreviewIcon: true,
            showDownloadIcon: true,
            downloadIcon: <span style={{'position': 'absolute', 'top': '0', 'right': '20px', 'width': '100%', 'height': '100%'}}></span>
            
        },
        onRemove: (file: any) => {
            setUploading(true);
            dispatch(docDelete({ params: {...docsGetParams, documentId: file.uid}, data: {archive: true} }))
            setMessageShown(true);
            setDocDeletedId(file.uid);
            setListing(false);
            return false
        },
        onDownload: (file: any) => {
            setDownloadClicked(true);
            setDownloadName(file.originalName);
            if(docType === 'any') {
                if(file.elType === 'ap') {
                    dispatch(authorizedPersonDocUrlBackGet({ params: {...docsGetParams, authorizedPersonId: file.userId, documentId: file.uid}, config: {responseType: 'blob'} }))
                }
                if (file.elType === 'ubo') {
                    dispatch(uboBackDocUrlGet({ params: {...docsGetParams, beneficialOwnerId: file.userId, documentId: file.uid}, config: {responseType: 'blob'} }))
                }
                if(file.elType === 'individual') {
                    dispatch(individualBackDocUrlGet({ params: {...docsGetParams, documentId: file.uid}, config: {responseType: 'blob'} }))
                }
                if(file.elType === 'company') {
                    dispatch(companyDocUrlGet({params: {...docsGetParams, documentId: file.uid}, config: {responseType: 'blob'}}))
                }
            } else {
                dispatch(docUrlGet({ params: {...docsGetParams, documentId: file.uid}, config: {responseType: 'blob'} }))
            }
        },
        beforeUpload: (file: any) => {
            return false;
        },
        onDrop: (file: any) => {
            //console.log(file);
        },
        onChange: (file: any) => {
            setListing(false);
            if(file.file.status !== 'removed') {
                handleUpload(file)
            } else {
                handleDelete(file)
            }
            
        },
        fileEl
    };
    let disabledEl = docType === "OtherDocumentKind" ? false : disabled;
  
	return (
        <>
            <>  
                
                {envs.admin &&
                    <label className='ant-form-item-label' style={{'width': '100%'}}>
                        <Row justify="space-between">
                            <Col></Col>
                            {/*<ApproveInput onSwitchChange={onSwitchChange} name={'documentId'} type='document' schemaParent={schemaParent} schemaName={schemaName} />*/}
                        </Row>
                    </label>
                }
                {fileList?.length ? (
                    <DraggerHeader className='dragger-header'><div>{name}</div></DraggerHeader>
                ) : (<></>)}
                <DraggerContainer className={fileList?.length ? 'dragger-body' : 'empty error dragger-body'}>
                    <Dragger 
                        className={isErrorMessage ? 'error' : ''} 
                        {...uploadProps}
                        id='firstPage'
                        disabled={disabledEl} 
                        fileList={fileList}
                        style={{'display' : envs.admin && !uploadAdmin ? 'none' : 'initial'}}
                        itemRender={(originNode, file, currFileList, actions) => (
                            <DragableUploadListItem
                                originNode={originNode}
                                actions={actions}
                                file={file}
                                fileList={currFileList}
                                personId={personId}
                                onSwitchChange={onSwitchChange}
                                type={type}
                                hideApprove={hideApprove}
                                docType={docType}
                            />
                        )}
                    >
                        <Space direction='vertical' size={15} >
                            <p className="ant-upload-drag-icon">
                              <InboxOutlined />
                            </p>
                            <p className="ant-upload-text"><b>{name}</b></p>
                            <p className="ant-upload-hint">
                                Click or drag file to this area to upload<br />
                                <span>Max file size is {maxSize ? maxSize : 8}MB</span>
                            </p>
                            <Button icon={<UploadOutlined />} loading={uploading} style={{'marginTop': '15px'}} disabled={disabledEl}>Click to upload</Button>
                        </Space>
                    </Dragger>
                    <ErrorContainer style={{'marginTop' : '3px'}}>
                        {isErrorMessage ? (
                            <div>At least one document should be uploaded</div>
                        ) : null}
                    </ErrorContainer>
                </DraggerContainer>
                {archivedFileList?.length && envs.admin ? (
                    <Row>
                        <Col span={24} style={{'textAlign': 'right', 'marginTop': '15px'}}>
                            <ButtonUpper btnType='dashed' className='btn-sm dashed-primary right' ghost onClick={handleShowArchived}>{showArchived ? 'Hide archived documents' : 'Show archived documents'}</ButtonUpper>
                        </Col>
                    </Row>
                ):(<></>)}
                {showArchived && envs.admin &&
                    <>
                        {archivedFileList?.length ? (
                            <div style={{marginTop: '30px'}}>
                                <DraggerHeader className='dragger-header dragger-disabled-header'><div>Archived {name}</div></DraggerHeader>
                            </div>
                        ) : (<></>)}
                        <DraggerContainer className={archivedFileList?.length ? 'dragger-body dragger-disabled-body' : 'empty error dragger-body dragger-disabled-body'}>
                            <Dragger 
                                className={isErrorMessage ? 'error' : ''} 
                                {...uploadProps} 
                                //showUploadList={{showRemoveIcon: true, removeIcon: <span className='aaa bbb'></span>}}
                                id='archivedPage' 
                                disabled={disabledEl} 
                                fileList={archivedFileList} 
                                style={{'display' : envs.admin ? 'none' : 'initial'}}
                                itemRender={(originNode, file, currFileList, actions) => (
                                  <DragableUploadListItem
                                    handleRestoreClick={() => handleRestoreClick(file)}
                                    originNode={originNode}
                                    actions={actions}
                                    archived={true}
                                    file={file}
                                    fileList={currFileList}
                                    personId={personId}
                                    onSwitchChange={onSwitchChange}
                                    type={type}
                                    hideApprove={true}
                                    docType={docType}
                                  />
                                )}
                                
                            >
                            </Dragger>
                        </DraggerContainer>
                    </>
                }
            </>
        </>
    )
}

const type = 'DragableUploadList';

interface DragableUploadListItemProps {
  originNode: React.ReactElement<any, string | React.JSXElementConstructor<any>>;
  file: UploadFile;
  archived?: boolean;
  actions: { download: () => void };
  type: 'ap' | 'ubo' | 'company' | 'individual'
  fileList: UploadFile[],
  personId?: string,
  docType?: string | Blob,
  hideApprove?: boolean,
  onSwitchChange: ((value: any) => void) | undefined,
  handleRestoreClick?: ((value: any) => void) | undefined
}

const DragableUploadListItem = ({originNode, file, archived, fileList, actions, personId, type, docType, hideApprove, onSwitchChange, handleRestoreClick}: DragableUploadListItemProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const index = fileList.indexOf(file);
  const envs = useSelector((state) => state.general.envs);
  const errorNode = <Tooltip title="Upload Error">{originNode.props.children}</Tooltip>;
  return (
    <div
      ref={ref}
      className={`ant-upload-draggable-list-item`}
      style={{ cursor: 'move' }}
    >
        <span onClick={() => actions.download()}>{file.status === 'error' ? errorNode : originNode}</span>
        {envs.admin && docType !== 'any' && !hideApprove && <ApproveInputOld name={file.uid} isDocument={true} type={type} personId={personId} />}
        {envs.admin && archived && docType !== 'any' && <div className='restore-icon'><ArrowUpOutlined title='Restore file' onClick={handleRestoreClick} /></div>}
    </div>
  );
};

export default FormUpload