import { createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../../api/kyc/api";
import { AsyncThunkActionType } from "../../store";
import { AsyncThunkDataType } from "../../common";
import { Components, Paths } from "../../../api/kyc/generated/client";
import { store } from "../../store";

type IndividualResponse = Paths.IndividualInternalMessageViewed.Responses.$200;
type PathParameters = Paths.IndividualInternalMessageViewed.PathParameters;
type indResp = {
  id: string,
  read: IndividualResponse
}

export const individualMessageViewedPut: AsyncThunkActionType<
  indResp,
  AsyncThunkDataType<PathParameters, null, "data">
> = createAsyncThunk(
  "individualMessageViewedPut",
  async ({ params, data, config }, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      const response = await api.IndividualInternalMessageViewed(params, data, config);
      return {id: params?.messageId, read: response.data} as indResp;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);
