import { createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../../api/kyc-backend/api";
import { AsyncThunkActionType } from "../../store";
import { AsyncThunkDataType } from "../../common";
import { CompanyResponse } from "../slices/finalApproveSlice";
import { Components, Paths } from "../../../api/kyc-backend/generated/client";

type ToggleRequest = Components.Schemas.ApproveRequest;
type PathParameters = Paths.ApproveCompanyToggle.PathParameters;

export const approveCompanyToggle: AsyncThunkActionType<
  CompanyResponse,
  AsyncThunkDataType<PathParameters, ToggleRequest, "data">
> = createAsyncThunk(
  "approveCompanyToggle",
  async ({ params, data, config }, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      const response = await api.ApproveCompanyToggle(params, data, config);
      return response.data as CompanyResponse;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);
