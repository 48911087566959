import { FC, useEffect, useState } from 'react'
import { PrivateRoute } from 'routes/PrivateRoute'
import { UserAdminRoute } from './UserAdminRoute'
import { kycApplicationGet } from 'store/kyc-front/asyncThunks/kycApplicationGet'
import { setStepsScheme } from 'store/kyc-front/slices/kycApplicationSlice'
import { AdminRoute } from 'routes/AdminRoute'
import { LazyComponent } from 'modules/layout/LazyComponent'
import { useIsUserLogged } from 'modules/keycloak'
import { createBrowserRouter, RouteObject, Outlet, useParams, RouterProvider } from 'react-router-dom'
import { useSelector } from 'hooks/useSelector'
import KycKeycloakFront from 'modules/kyc-front/KycKeycloakFront'
import KycKeycloakBack from 'modules/kyc-backend/KycKeycloakBack'
import { IndividualSteps, CompanySteps, APSteps, UboSteps, Step } from 'stepsScheme'
import Login from 'modules/kyc-front/Login'
import KycRegister from 'modules/kyc-front/KycRegister'
import { Navigate } from 'react-router-dom'
import Error403 from 'modules/kyc-front/Error403'
import KycIntro from 'modules/kyc-front/steps/KycIntro'
import KycForm, {FProps} from 'modules/kyc-front/forms/KycForm'
import KycCompanyInfo from 'modules/kyc-front/steps/KycCompanyInfo'
import KycAdminCompanyInfo from 'modules/kyc-backend/steps/KycCompanyInfo'
import KycIndividualInfo from 'modules/kyc-front/steps/KycIndividualInfo'
import KycAdminIndividualInfo from 'modules/kyc-backend/steps/KycIndividualInfo'
import KycAuthorizedPersons from 'modules/kyc-front/steps/KycAuthorizedPersons'
import KycAdminAuthorizedPersons from 'modules/kyc-backend/steps/KycAuthorizedPersons'
import KycUbos from 'modules/kyc-front/steps/KycUbos'
import KycAdminUbos from 'modules/kyc-backend/steps/KycUbos'
import KycIndividualDeclarations from 'modules/kyc-front/steps/KycIndividualDeclarations'
import KycAdminIndividualDeclarations from 'modules/kyc-backend/steps/KycIndividualDeclarations'
import KycDataValidation from 'modules/kyc-front/steps/KycDataValidation'
import KycAdminDataValidation from 'modules/kyc-backend/steps/KycDataValidation'
import KycInbox from 'modules/kyc-front/steps/KycInbox'
import ClientListing from 'modules/kyc-backend/views/ClientListing'
import ClientArchivedListing from 'modules/kyc-backend/views/ClientArchivedListing'
import AdminMessages from 'modules/kyc-backend/views/AdminMessages'
import BusinessRelation from 'modules/kyc-backend/views/BusinessRelation'
import BusinessProfile from 'modules/kyc-backend/views/BusinessProfile'
import RiskProfile from 'modules/kyc-backend/views/RiskProfile'
import WorldCheck from 'modules/kyc-backend/views/WorldCheck'
import Transactions from 'modules/kyc-backend/views/Transactions'
import KycAdminSummary from 'modules/kyc-backend/steps/KycAdminSummary'
import { dispatch } from 'store/store'

export const PATH = {
	LOGIN: '/user/login',
	ADMIN_LOGIN: '/admin/login',
	REGISTER: '/user/register'
}
export const BASE_PATH = `/user`
export const ADMIN_BASE_PATH = `/admin`

interface UProps {
	companyComponent: any,
	individualComponent?: any,
	isAdmin?: boolean
}

interface LProps {
	component: any
}


const LoggedOutRoute: FC<LProps> = ({ component }) => {
	const { isUserLogged } = useIsUserLogged()
    return isUserLogged ? <Navigate to="/BASE_PATH" /> : component;
}

const UserTypeRoute: FC<UProps> = ({ companyComponent, individualComponent, isAdmin }) => {
    let isBusiness = useSelector((state) => state.general.envs.isBusiness);
    return isBusiness ? companyComponent : individualComponent ? individualComponent : <Navigate to={isAdmin ? ADMIN_BASE_PATH : BASE_PATH} />;
}
const RedirectToSummary: FC = () => {
	let { id } = useParams();
	return <Navigate to={`${ADMIN_BASE_PATH}/${id}/summary`} />;
};

const useDynamicSteps = (type: 'admin'|'user') => {
	const [apIndex, setApIndex] = useState(-1);
	const isBusiness = useSelector((state) => state.general.envs.isBusiness);
	const { kycApplication } = useSelector((state) => state.user2.kycApplication);
	const envs = useSelector((state) => state.general.envs);
	const [iteratedSteps, setIteratedSteps] = useState<Step[]>([]);
	const [dynamicSteps, setDynamicSteps] = useState<RouteObject[]>([]);
	const path = type === 'admin' ? ADMIN_BASE_PATH + '/' + envs.profileId : BASE_PATH
	useEffect(() => {
	  if (envs.profileId !== '') {
		dispatch(kycApplicationGet({ params: { id: envs.profileId } }));
	  }
	}, [envs.profileId]);
  
	useEffect(() => {
	  const generateSteps = (stepsScheme: Step[], authorizedPersons: any[], beneficialOwners: any[]): Step[] => {
		let newSteps = [...stepsScheme];
		const authorizedPersonsIndex = newSteps.findIndex(step => step.type === 'authorizedPersons');
		setApIndex(authorizedPersonsIndex);
		if (authorizedPersonsIndex !== -1 && authorizedPersons) {
		  const newAuthorizedPersonsSteps = authorizedPersons.flatMap((person, j) => {
			let parLink = `/step${authorizedPersonsIndex + 1}/${person.id}`
			return ([
			{
			  step: authorizedPersonsIndex + 1,
			  name: `${person.firstName ? person.firstName : 'New'} ${person.lastName ? person.lastName : 'Person'}`,
			  ref: 'ap',
			  refId: person.id,
			  hasSubsteps: true,
			  link: parLink,
			  navigateTo: `${parLink}/step1`,
			  fields: person.fields
			},
			...APSteps.map((step, k) => {
				let link = `/step${authorizedPersonsIndex + 1}/${person.id}/step${k + 1}`
				return ({
					step: authorizedPersonsIndex + 1,
					name: `${person.firstName ? person.firstName : 'New'} ${person.lastName ? person.lastName : 'Person'} - ${step.name}`,
					link: link,
					ref: 'ap',
					refId: person.id,
					substep: k + 1,
					fields: step.fields 
				})
			})
		  ])});
  
		  newSteps = newSteps.filter(step => step.ref !== 'ap');
		  newSteps = [
			...newSteps.slice(0, authorizedPersonsIndex + 1),
			...newAuthorizedPersonsSteps,
			...newSteps.slice(authorizedPersonsIndex + 1)
		  ];
		}
  
		const ubosIndex = newSteps.findIndex(item => item.type === 'beneficialOwners');
		if (ubosIndex !== -1 && beneficialOwners) {
		  const ubosStepValue = newSteps[ubosIndex].step;
		  const newBeneficialOwnersSteps = beneficialOwners.flatMap((owner, j) => {
			let parLink = `/step${ubosStepValue}/${owner.id}`
			return ([
			{
			  step: ubosStepValue,
			  name: `${owner.firstName ? owner.firstName : 'New'} ${owner.lastName ? owner.lastName : 'Person'}`,
			  ref: 'ubo',
			  refId: owner.id,
			  link: parLink,
			  navigateTo: `${parLink}/step1`,
			  hasSubsteps: true,
			  fields: owner.fields 
			},
			...UboSteps.map((step, k) => {
				let link = `/step${ubosStepValue}/${owner.id}/step${k + 1}`
				return ({
					step: ubosStepValue,
					name: `${owner.firstName ? owner.firstName : 'New'} ${owner.lastName ? owner.lastName : 'Person'} - ${step.name}`,
					link: link,
					ref: 'ubo',
					refId: owner.id,
					substep: k + 1,
					fields: step.fields 
					})
				})
		  ])});
  
		  newSteps = newSteps.filter(step => step.ref !== 'ubo');
		  newSteps = [
			...newSteps.slice(0, ubosIndex + 1),
			...newBeneficialOwnersSteps,
			...newSteps.slice(ubosIndex + 1)
		  ];
		}
  
		return newSteps;
	  }
  
	  const stepsScheme = isBusiness ? CompanySteps : IndividualSteps;
	  //@ts-ignore
	  const newIteratedSteps = generateSteps(stepsScheme, kycApplication.values?.authorizedPersons, kycApplication.values?.beneficialOwners);
	  setIteratedSteps(newIteratedSteps);
	  dispatch(setStepsScheme(newIteratedSteps));
	  const createRouteForStep = (stepEl: any, i: number, next: any, prev: any, isSection: boolean, type?: string): RouteObject[] => {
		const formEl = <KycForm index={i} form={stepEl} refId={stepEl.refId} prev={prev} next={next} isSection={isSection} type={stepEl.ref ? stepEl.ref : stepEl.type} />;
		const baseRoute: RouteObject = {
		  path: path + stepEl.link,
		  element: stepEl.navigateTo ? <Navigate to={path + stepEl.navigateTo} /> : type === 'admin' ? <PrivateRoute><UserAdminRoute>{formEl}</UserAdminRoute></PrivateRoute> : <PrivateRoute>{formEl}</PrivateRoute>
		};
		if (stepEl.steps && stepEl.steps.length > 0) {
		  return [
			baseRoute,
			...stepEl.steps.flatMap((subStep: any, j: number) => createRouteForStep(subStep, j, stepEl.steps[j + 1], stepEl.steps[j - 1], false))
		  ];
		}
  
		return [baseRoute];
	  };
  
	  const newDynamicSteps = newIteratedSteps.flatMap((stepEl, i) => createRouteForStep(stepEl, i, newIteratedSteps[i + 1], newIteratedSteps[i - 1], stepEl.substep ? true : false));
	  setDynamicSteps(newDynamicSteps);
	  //@ts-ignore
	}, [kycApplication.values?.authorizedPersons, kycApplication.values?.beneficialOwners, isBusiness, envs.profileId]);
	return dynamicSteps;
  }

export const AppRoutes = () => {
    const dynamicSteps = useDynamicSteps('user');
	const dynamicAdminSteps = useDynamicSteps('admin')
    const appRoutes = createBrowserRouter(
	[
		{
			path: PATH.LOGIN + '/:redirect?',
			element: <Login isBackend={false} />,
			index: true,
		},
		{
			path: PATH.ADMIN_LOGIN + '/:redirect?',
			element: <Login isBackend={true} />,
			index: true,
		},
		{
			path: PATH.REGISTER,
			element: <LoggedOutRoute component={<KycRegister />} />
		},
		{
			path: '/403',
			element: <Error403 />
		},
		{
			path: BASE_PATH,
			element: <KycKeycloakFront />,
			children: [
				{
					path: BASE_PATH,
					element: <Navigate to={BASE_PATH + "/profile"} />,
				},
				{
					path: BASE_PATH + '/profile',
					element: <PrivateRoute><KycIntro /></PrivateRoute>,
				},
				...dynamicSteps,
				{
					path: BASE_PATH + '/*',
					element: <PrivateRoute><></></PrivateRoute>,
				},
				{
					path: BASE_PATH + '/*/*',
					element: <PrivateRoute><></></PrivateRoute>,
				},
				{
					path: BASE_PATH + '/*/*/*',
					element: <PrivateRoute><></></PrivateRoute>,
				},
				{
					path: BASE_PATH + '/inbox',
					element: <PrivateRoute><KycInbox /></PrivateRoute>
				}
			],
		},
		{
			path: ADMIN_BASE_PATH,
			element: <KycKeycloakBack />,
			children: [
				{
					path: ADMIN_BASE_PATH,
					element: <PrivateRoute><AdminRoute><ClientListing /></AdminRoute></PrivateRoute>,
				},
				{
					path: ADMIN_BASE_PATH + '/archived',
					element: <PrivateRoute><AdminRoute><ClientArchivedListing /></AdminRoute></PrivateRoute>,
				},
				{
					path: ADMIN_BASE_PATH + '/messages',
					element: <PrivateRoute><AdminRoute><AdminMessages /></AdminRoute></PrivateRoute>,
				},
				{
					path: ADMIN_BASE_PATH + '/:id/relation',
					element: <PrivateRoute><UserAdminRoute><BusinessRelation /></UserAdminRoute></PrivateRoute>,
				},
				{
					path: ADMIN_BASE_PATH + '/:id/business-profile',
					element: <PrivateRoute><UserAdminRoute><BusinessProfile /></UserAdminRoute></PrivateRoute>,
				},
				{
					path: ADMIN_BASE_PATH + '/:id/business-profile/*',
					element: <PrivateRoute><UserAdminRoute><BusinessProfile isArchived={true} /></UserAdminRoute></PrivateRoute>,
				},
				{
					path: ADMIN_BASE_PATH + '/:id/risk-profile',
					element: <PrivateRoute><UserAdminRoute><RiskProfile /></UserAdminRoute></PrivateRoute>,
				},
				{
					path: ADMIN_BASE_PATH + '/:id/risk-profile/*',
					element: <PrivateRoute><UserAdminRoute><RiskProfile isArchived={true} /></UserAdminRoute></PrivateRoute>,
				},
				{
					path: ADMIN_BASE_PATH + '/:id/world-check',
					element: <PrivateRoute><UserAdminRoute><WorldCheck /></UserAdminRoute></PrivateRoute>,
				},
				{
					path: ADMIN_BASE_PATH + '/:id/transactions',
					element: <PrivateRoute><UserAdminRoute><Transactions /></UserAdminRoute></PrivateRoute>,
				},
				{
					path: ADMIN_BASE_PATH + '/:id/summary',
					element: <PrivateRoute><UserAdminRoute><KycAdminSummary /></UserAdminRoute></PrivateRoute>,
				},
				...dynamicAdminSteps,
				{
					path: ADMIN_BASE_PATH + '/:id/*',
					element: <PrivateRoute><UserAdminRoute><></></UserAdminRoute></PrivateRoute>,
				},
				{
					path: ADMIN_BASE_PATH + '/:id/*/*',
					element: <PrivateRoute><UserAdminRoute><></></UserAdminRoute></PrivateRoute>,
				},
				{
					path: ADMIN_BASE_PATH + '/:id/*/*/*',
					element: <PrivateRoute><UserAdminRoute><></></UserAdminRoute></PrivateRoute>,
				},
				{
					path: ADMIN_BASE_PATH + '/:id',
					element: <RedirectToSummary />
				},
			],
		},
		{
			path: '*',
			element: <Navigate to={BASE_PATH} />,
		},
	],
	{
		basename: '/',
		future: { v7_normalizeFormMethod: true },
	},
	)
	return <RouterProvider router={appRoutes} fallbackElement={null} future={{ v7_startTransition: true }} />;
};





