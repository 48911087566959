import { Formik, Form, useFormikContext } from 'formik'
import _ from 'lodash'
import * as Yup from 'yup'
import { FC, useState, useEffect, ReactNode } from 'react'
import { useSelector } from '../../../hooks/useSelector'
import { useActionStatus } from '../../../hooks/useActionStatus'
import { Row, Col, Space, Popconfirm, message, Result, Typography } from 'antd'
import { CloseCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import { Link, useNavigate } from 'react-router-dom'
import { moment } from '../ui-elements/FormDate'
import { dispatch } from '../../../store/store'
import { kycApplicationValidate } from 'store/kyc-front/asyncThunks/kycApplicationValidate'
import { userCreate } from '../../../store/kyc-front/asyncThunks/userCreate'
import { profileGet } from '../../../store/kyc-front/asyncThunks/profileGet'
import { individualGet } from '../../../store/kyc-front/asyncThunks/individualGet'
import { companyProfileBackGet } from '../../../store/kyc-backend/asyncThunks/companyProfileBackGet'
import { individualProfileBackGet } from '../../../store/kyc-backend/asyncThunks/individualProfileBackGet'
import { kycApplicationSectionDelete } from 'store/kyc-front/asyncThunks/kycApplicationSectionDelete'
import { kycApplicationSectionDeleteClear, kycApplicationSubmitClear } from 'store/kyc-front/slices/kycApplicationSlice'
import { adminProfileGet } from '../../../store/kyc-backend/asyncThunks/adminProfileGet'
import { redirectToError } from '../../../store/kyc-front/slices/errorsSlice'
import { setFormUpdated, setCurrentEditedForm, setEditedFormConfirmed } from '../../../store/kyc-front/slices/navSlice'
import { setEnvs } from '../../../store/kyc-front/slices/envsSlice'
import styled from 'styled-components'
import { themeColor, themeScreen } from '../../../styles/themeStyles'
import { ButtonUpper } from '../ui-elements/Buttons'
import { ErrorElement, ErrorParagraph } from '../ui-elements/ErrorElementNew'
import { ColRight } from '../ui-elements/Columns'
import { kycApplicationSubmit } from 'store/kyc-front/asyncThunks/kycApplicationSubmit'
import { kycApplicationFinalApprove } from 'store/kyc-backend/asyncThunks/kycApplicationFinalApprove'
import { kycApplicationFinalApproveClear } from 'store/kyc-backend/slices/backKycApplication'

const { Paragraph, Text } = Typography

const CustomLinkSpan = styled.span`
    cursor: pointer;
    border: 1px solid ${themeColor.grayBasic};
    padding: 2px 7px;
    &.disabled-link {
      pointer-events: none;
      li {
        color: ${themeColor.grayLight};
      }
    }
`
const CustomLink = styled.span`
    cursor: pointer;
`

const LinkEl = styled(Link)`
    border: 1px solid ${themeColor.grayBasic};
    padding: 2px 7px;
`

const PopconfirmCustom = styled(Popconfirm)`
    .ant-popover {
        min-width: 350px;
        text-transform: none;
    }
`

const StickyNav = styled.div`
    position: fixed;
    z-index: 10;
	width: 100%;
	.content {
		max-width: ${themeScreen.maxContent}
	}
    &.bottom {
        bottom: 0;
    }
    &.no-stick {
        position: static;
        bottom: 0;
        border: 0;
    }
`

const StickyContent = styled.div`
    border-top: 2px solid ${themeColor.grayWhite};
    border-bottom: 2px solid ${themeColor.grayWhite};
    padding: 15px 0;
    background-color: #fff;
    
`

Yup.setLocale({
    mixed: {
        required: 'Field is required',
        default: 'Value is invalid',
        notType: ({ path, type, value, originalValue }) => `Value should be a ${type}`
    },
    number: {
        integer: 'Value should be a number',
        positive: 'Value should be positive number',
        min: 'Value should be at min ${min}',
    }
})

interface FProps {
    id: string,
    type?:  'authorized-persons' | 'ubos',
    initial: object,
    initialErrors?: object,
    validation: any,
    //dispatchSubmit: (props: any) => Action,
    dispatchSubmit?: any,
    params?: {
        applicationId?: string,
        sectionName?: string,
        sectionId?: string,
        documentId?: string,
        //todo remove below params when implementing v2 api in forms outside of kyc
        companyId?: string,
        individualId?: string,
        authorizedPersonId?: string,
        beneficialOwnerId?: string,
        id?: string
    },
    dataFormat? : any,
    ommitFields?: Array<string>,
    dispatchClear?: any,
    dispatchGet?: any,
    successCondition: any,
    saveInnerForm?: boolean,
    deleteForm?: boolean,
    restoreForm?: boolean,
    validateButton?: boolean,
    finalSubmit?: boolean,
    loginSubmit?: boolean,
    registerSubmit?: boolean,
    saveText?: string,
    saveDraft?: boolean,
    hideSaving?: boolean,
    transformBools?: boolean,
    printRender?: boolean,
    validationAction?: any,
    validatedElem?: any,
    standaloneFormSubmit?: boolean,
    editable?: boolean,
    hideOnDisabled?: boolean,
    validateOnBlur?: boolean,
    approveErrorsListing?: boolean,
    children: (props: any) => ReactNode
}
interface OProps {
    type?: 'authorized-persons' | 'ubos'
}

export const scrollToErrors = (id: string) => {
    // @ts-ignore
    document.getElementById(id).querySelectorAll('.error')[0]?.scrollIntoView({ behavior: 'smooth', block: 'center' })
}

const getChangedValues = (initial: any, updated: any) => {
    const changedValues: any = {}
    
    Object.keys(updated).forEach(key => {
      if (initial[key] !== updated[key]) {
        changedValues[key] = updated[key]
      }
    })
    
    return changedValues;
}

const FormObserver: FC<OProps>  = ({type}) => {
    const {initialValues, values} = useFormikContext()
    useEffect(() => {
        dispatch(setCurrentEditedForm({values: getChangedValues(initialValues, values), type: type}))
    }, [values])
    return null
};

function getOffset(el: any) {
  const rect = el?.getBoundingClientRect()
  return {
    left: rect?.left + window.scrollX,
    top: rect?.top + window.scrollY
  }
}

const BasicFormWrapper: FC<FProps> = ({
    id, type, initial, validation, dispatchSubmit, params, dataFormat, ommitFields, dispatchClear, dispatchGet, successCondition, saveInnerForm, deleteForm, restoreForm, validateButton, finalSubmit, loginSubmit, registerSubmit, saveText, saveDraft, hideSaving, transformBools, printRender, validationAction, validatedElem, standaloneFormSubmit, editable, hideOnDisabled, validateOnBlur, approveErrorsListing, children
}) => {
    const envs = useSelector((state) => state.general.envs)
    const isUbo = params?.sectionName === 'ubos'
    const [formDisabled, setDisabledForm] = useState(false)
    const [messageShown, setMessageShown] = useState(finalSubmit || loginSubmit || registerSubmit ? false : true)
    let [popupVisible, setPopupVisible] = useState(false)
    let [popupEditVisible, setPopupEditVisible] = useState(false)
    let [deletePopupVisible, setDeletePopupVisible] = useState(false)
    let [popupBackVisible, setPopupBackVisible] = useState(false)
    let [errorsListing, setErrorsListing] = useState(false)
    let [visibleErrors, setVisibleErrors] = useState<string[]>([])
    let [disabled, setDisabled] = useState(false)
    let [isDeleting, setDeleting] = useState(false)
    const [initialErrors, setInitialErrors] = useState({})
    const nav = useSelector((state) => state.general.nav)
    const navigate = useNavigate()
    const profile = useSelector((state) => state.user.profile)
    const backCompany = useSelector((state) => state.admin.backCompany)
    const errorsState = useSelector((state) => state.general.errorsState)
    const kycApplication = useSelector((state => state.user2.kycApplication))
    const [finalSubmitting, setFinalSubmitting] = useState(false)
    const validationFullResponse = kycApplication.validationFullResponse.payload?.validationErrors
    const apsLink = kycApplication.stepsScheme.find(el => el.type ==='authorizedPersons')?.link
    const ubosLink = kycApplication.stepsScheme.find(el => el.type ==='beneficialOwners')?.link
    const { userProfile } = profile
    const individual = useSelector((state) => state.user.individual)
    const backIndividual = useSelector((state) => state.admin.backIndividual)
    const admin = useSelector((state) => state.admin.admin)
    const {kycApplicationApprovals, approvalErrors} = useSelector((state) => state.admin2.applicationApprovals)
    let {prev} = nav.nav
    let {formUpdated } = nav
    const { isError, isFullfilled } = useActionStatus(dispatchSubmit)
    const actionValidationStatus = useActionStatus(validationAction)
    const deleteEl = useActionStatus(kycApplicationSectionDelete)
    const finalSubmitStatus = useActionStatus(kycApplicationSubmit)
    const finalApproveStatus = useActionStatus(kycApplicationFinalApprove)
    const [fixedNavPos, setFixedNavPos] = useState(true)
    const [contentWidth, setContentWidth] = useState(0)
    const directorError = validationFullResponse?.find((err: any) => err.ref === 'authorizedPersons' && err.slug === 'Director')
    const beneficialOwnerError = validationFullResponse?.find((err: any) => err.ref === 'beneficialOwners' && err.slug === 'required')
    const signatoryError = validationFullResponse?.find((err: any) => err.ref === 'authorizedPersons' && err.slug === 'signatories')
    const approvalError = approvalErrors.find((err: any) => err.validationErrors.length)
	const approvalDocError = approvalErrors.find((err: any) => err.docErrors.length)
    const signatoryApprovalError = !(
        kycApplicationApprovals.authorizedPersons?.flatMap((person: any) => person.approvals)
          .filter((approval: any) => approval.slug === 'isSignatory')
          .every((approval: any) => approval.isApprovalRequirementSatisfied) ?? true
    );
    useEffect(() => {
        const onScroll = () => {
            let topPos = window.pageYOffset + 600
            let formPos = getOffset(document.getElementById("dataValidationForm")).top
            let res = topPos - formPos
            if(res <= 0) {
                setFixedNavPos(true)
            }
            if(res > 0) {
                setFixedNavPos(false)
            }
        }
        if(envs.accountType === 'PRIVATE' && finalSubmit) {
            // clean up code
            window.removeEventListener('scroll', onScroll)
            window.addEventListener('scroll', onScroll, { passive: true })
        }  
        //@ts-ignore
        setContentWidth(document?.getElementById("pageWrapper") ? document?.getElementById("pageWrapper").offsetWidth : 0)     
        return () => window.removeEventListener('scroll', onScroll)
    }, [])
    let isStandaloneAdmin = envs.admin && envs.type === 'standalone'
    const setUpErrors = (validatedEl: any) => {
        let errors = {}
        let errorsElem = validatedEl
        if(validatedEl) {
            if(errorsElem) {
                if(errorsElem && errorsElem.length) {
                    //@ts-ignore
                    errorsElem.forEach((error: any) => {
                        if(error.ref === 'missing-passport-document') {
                            //@ts-ignore
                            errors.PassportFront = true
                        }
                        if(error.ref === 'missing-front end back-of-id-card') {
                            //@ts-ignore
                            errors.IDCardFront = true
                            //@ts-ignore
                            errors.IDCardBack = true
                        }
                        if(error.ref === 'missing-front-of-the-id-card') {
                            //@ts-ignore
                            errors.IDCardFront = true
                        }
                        if(error.ref === 'missing-back-of-the-id-card') {
                            //@ts-ignore
                            errors.IDCardBack = true
                        }
                        if(error.ref === 'proof-of-address') {
                            //@ts-ignore
                            errors.ProofOfAddress = true
                        }
                        if(error.ref === 'proof-of-address') {
                            //@ts-ignore
                            errors.ProofOfAddress = true
                        }
                            if(error.ref !== '') {
                                //@ts-ignore
                                if(errors[error?.ref]) {
                                    //@ts-ignore
                                    errors[error?.ref][error?.slug] = 'Field is required'
                                } else {
                                    //@ts-ignore
                                    errors[error?.ref] = {}
                                    //@ts-ignore
                                    errors[error?.ref][error?.slug] = 'Field is required'
                                }
                            } else {
                                //@ts-ignore
                                errors[error.slug] = 'Field is required'
                            }
                        //}
                        
                        
                    })
                    let fieldName = ''
                    if(errorsState) {
                        //@ts-ignore
                        fieldName = errorsState?.error?.ref ? errorsState?.error?.ref + '.' + errorsState?.error?.slug : errorsState?.error?.slug
                    }
                    if(Array.isArray(validatedEl)) {
                        setInitialErrors(errors)
                        let timer
                        let timerTimeout = 400
                            if(errorsState.error !== undefined && !_.isEmpty(errorsState.error)) {
                                {/*if (errorsState.formName === id) {*/}
                                    timer = setTimeout(() => scrollToErrors('root'), timerTimeout)
                                {/*}}*/}
                            } else {
                                timer = setTimeout(() => scrollToErrors('root'), timerTimeout)
                            }
                        
                        
                        
                    } else {
                        if(envs.isPrivate) {
                            setInitialErrors(errors)
                        } else {
                            setInitialErrors(errors)
                        }
                        let timer
                        let timerTimeout = 400
                            if(errorsState.error !== undefined && !_.isEmpty(errorsState.error)) {
                                {/*if(errorsState.formName === id) {*/}
                                    timer = setTimeout(() => scrollToErrors('root'), timerTimeout)
                                {/*}}*/}
                            } else {
                                timer = setTimeout(() => scrollToErrors('root'), timerTimeout)
                            }
                        
                    }
                    dispatch(redirectToError({}))
                } else {
                    if(actionValidationStatus.isFullfilled) {
                        setInitialErrors({})
                    }
                }
            }
        }
    }
    useEffect(() => {
        if(!_.isEmpty(errorsState) && (errorsState.formName === id)) {
            dispatch(validationAction({ params: { id: envs.profileId, step: nav.nav.step } }))
        }
    }, [errorsState, id])
    useEffect(() => {
        if(envs.isBusiness) {
            if(envs.admin) {
                if(backCompany?.profile?.companyId === '' && !registerSubmit && !loginSubmit) {
                    dispatch(companyProfileBackGet({ params: { companyId: envs.profileId } }))
                }
            } else {
                if(userProfile.companyId === '' && !registerSubmit && !loginSubmit) {
                    dispatch(profileGet({ params: { companyId: envs.profileId } }))
                }
            }
        } else {
            if(envs.admin) {
                if(backIndividual.individual.individualId === '' && !registerSubmit && !loginSubmit)  {
                    dispatch(individualProfileBackGet({ params: { individualId: envs.profileId } }))
                }
            } else {
                if(individual.individual.individualId === '' && !registerSubmit && !loginSubmit) {
                    dispatch(individualGet())
                }
            }    
        }

    }, [])
    useEffect(() => {
        let reviewStatus = kycApplication.kycApplication.status
        if((reviewStatus === 'SUBMITTED' || reviewStatus === 'ACCEPTED' || reviewStatus === 'REJECTED') && (disabled === false) && id !== 'loginForm' && id !== 'registerForm') {
            setDisabled(true)
        }
        if((reviewStatus === 'NEW') && (disabled === true)) {
            setDisabled(false)
        }
    }, [kycApplication.kycApplication])
    useEffect(() => {
        setUpErrors(validatedElem)
    }, [validatedElem])
    useEffect(() => {
        if(envs.admin && admin.profile.adminId === '' && id !== 'loginForm' && id !== 'registerForm') {
            dispatch(adminProfileGet({params: null}))
        }
    }, [admin])
    useEffect(() => {
        if((finalApproveStatus.isFullfilled || finalApproveStatus.isError) && messageShown === false) {
            setMessageShown(true)
        }
        if(messageShown) {
            if(finalSubmit && (finalSubmitting || envs.admin && (finalApproveStatus.isFullfilled || finalApproveStatus.isError))) {
                if(actionValidationStatus.isFullfilled) {
                    const isKycValid = checkValidityOfKYC()
                    if(!isKycValid || finalApproveStatus.isError) {
                        message.error('Some error occured while posting this form')
                        setMessageShown(false)
                        setErrorsListing(true)
                        if(envs.admin) {
                            dispatch(kycApplicationFinalApproveClear({}))
                        }
                    } else {
                        if(envs.admin) {
                            dispatch(kycApplicationFinalApproveClear({}))
                            setMessageShown(false)
                            navigate(envs.routeSuffix + '/summary')
                        } else {
                            //@ts-ignore
                            dispatch(kycApplicationSubmit({params: params}))
                            //todo some redirect ??
                        }
                        setErrorsListing(false)
                    }
                    setFinalSubmitting(false)
                }
            } 
        }
    }, [finalSubmitting, finalApproveStatus, messageShown])
    useEffect(() => {
        if(approveErrorsListing) {
            if(finalSubmit && envs.admin) {
                message.error('Some error occured while posting this form')
                setMessageShown(false)
                setErrorsListing(true)
            } 
        }
    }, [approveErrorsListing])
    useEffect(() => {
        if(messageShown) {
            if(finalSubmit) {
                if(finalSubmitStatus.isFullfilled) {
                    message.success('Form was submitted successfully')
                    dispatch(kycApplicationSubmitClear({}))
                    if(dispatchGet) {
                        dispatch(dispatchGet({ params: { id: envs.profileId } }))
                    } 
                    dispatch(kycApplicationValidate({ params: { id: envs.profileId, step: nav.nav.step} }))
                    setMessageShown(false)
                    navigate(envs.routeSuffix + '/profile')
                }
                if(finalSubmitStatus.isError) {
                    message.error('Some error occured while posting this form')
                    dispatch(kycApplicationSubmitClear({}))
                    setMessageShown(false)
                }
            }
        }
    }, [finalSubmitStatus])
    useEffect(() => {
        //if(messageShown) {
            if(isFullfilled && (successCondition !== '')) {
                if(finalSubmit) {
                    if(envs.admin) {
                        //@ts-ignore
                        dispatch(kycApplicationSubmit({params: params}))
                    }
                    
                    if(kycApplicationSubmitClear) dispatch(kycApplicationSubmitClear({}))
                } else {
                    message.success('Form was sucessfully saved')
                    if(dispatchClear) dispatch(dispatchClear({}))
                    if(dispatchGet) {
                        dispatch(dispatchGet({ params: { id: envs.profileId } }))
                        //dispatch(kycApplicationValidate({ params: { id: envs.profileId, step: nav.nav.step} }))
                        //if(actionValidationStatus.isCleared) {
                            dispatch(validationAction({ params: { id: envs.profileId, step: nav.nav.step } }))
                       // }
                    }
                    setMessageShown(false)
                }
                
            }
            if(isError) {
                message.error('Some error occured while saving this form')
                if(dispatchClear) dispatch(dispatchClear({}))
                setMessageShown(false)
            }
        //}
    }, [isFullfilled, isError, successCondition])
    useEffect(() => {
        if(messageShown) {
            if(deleteEl?.isFullfilled && isDeleting) {
                message.success('Element was successfully deleted')
                dispatch(kycApplicationSectionDeleteClear({}))
                if(dispatchGet) {
                    dispatch(dispatchGet({ params: { id: envs.profileId } }))
                }
                dispatch(kycApplicationValidate({ params: { id: envs.profileId, step: nav.nav.step} }))
                setMessageShown(false)
                setDeleting(false)
            }
            if(deleteEl?.isError) {
                message.error('Some error occured while deleting this element')
                dispatch(kycApplicationSectionDeleteClear({}))
                setMessageShown(false)
            }
        }
        
    }, [kycApplication.sectionDeleted])
    useEffect(() => {
        return () => {
            if(dispatchClear) dispatch(dispatchClear({}))
        };
      }, [])
    useEffect(() => {
        if(errorsListing && finalSubmit) {
            //@ts-ignore
            document.getElementById("errorSummaryUser").scrollIntoView({ behavior: 'smooth', block: 'center' })
        }
    }, [errorsListing])
    useEffect(() => {
        if(errorsListing) {
            const isKycValid = checkValidityOfKYC()
            if(isKycValid) {
                setErrorsListing(false)
            }
        }
    }, [kycApplication.validation, directorError, beneficialOwnerError, signatoryError, approvalError, approvalDocError])
    const formChangedUpdate = (update: boolean) => {
        if(update) {
            if(formUpdated.updated === false) {
                dispatch(setFormUpdated({updated: true, form: [id], formName: type}))
            }
            if(formDisabled) {
                setDisabledForm(false)
            }
        } else {
            if(formUpdated.updated === true) {
                dispatch(setFormUpdated({updated: false, form: []}))
            }
        }
       
    }
    const finalSubmitAction = () => {
        setFinalSubmitting(true)
        setMessageShown(true)
        setDisabledForm(true)
    }
    const checkValidityOfKYC = () => {
        const validationErrors = kycApplication.validation.find(stepEl => stepEl.validationErrors.length) 
		if(validationErrors || directorError || beneficialOwnerError || signatoryError || (envs.admin && (approvalError || approvalDocError || signatoryApprovalError) )) {
			return false
		} else {
			return true
		}
	}

    const handleInputChange = (el: string) => {
        formChangedUpdate(true)
    }
    const cancelDelete = () => {
        setDeletePopupVisible(false)
    }
    const navBack = (link?: string) => {
        if(link) {
            dispatch(setEditedFormConfirmed({onConfirm: true, onConfirmLink: link}))
        }
    }
    const handleFormDelete = (restore?: boolean) => {
        dispatch(setFormUpdated({updated: true, form: [id], formName: type}))
        setDeleting(true)
        setMessageShown(true)
        //@ts-ignore
        dispatch(kycApplicationSectionDelete({params: params}))
    }
    const handleDeleteVisibleChange = () => {
        setDeletePopupVisible(!deletePopupVisible)
    }
    const showErrors = (el: string) => {
        if(visibleErrors.includes(el)) {
            setVisibleErrors(visibleErrors.filter(elem => elem !== el))
        } else {
            setVisibleErrors((visibleErrors) => [...visibleErrors, el])
        }
        
    }
    //@ts-ignore
    const onSubmit = (values, silent) => {
        setDisabledForm(true)
        formChangedUpdate(false)
        let submitValues = {...values}
        if(ommitFields) {
            ommitFields.forEach((el: string) => {
                delete submitValues[el as keyof typeof submitValues]
            })
        }
        if(transformBools) {
            Object.entries(submitValues).forEach(([i, value]) => {
                if(value === 'true') {
                    // @ts-ignore
                    submitValues[i as keyof typeof submitValues] = true
                }
                if(value === 'false') {
                    // @ts-ignore
                    submitValues[i as keyof typeof submitValues] = false
                    //delete submitValues[i as keyof typeof submitValues]
                }
            })
        }
        if(dispatchSubmit) {
            dispatch(
                dispatchSubmit({
                    params: params,
                    data: dataFormat ? dataFormat(submitValues) : submitValues
                }),
            )
        }
        
        if(!silent) {
            setMessageShown(true)
        }
        
    }
    return (
        <>
            <Formik
                initialValues={initial}
                validateOnBlur={validateOnBlur ? validateOnBlur : false}
                validateOnChange={false}
                validateOnMount={false}
                initialErrors={initialErrors}
                onReset={() => {
                   //console.log('reset')
                }}
                enableReinitialize={true}
                validationSchema={validation}
                onSubmit={(values) => {
                    onSubmit(values, false) 
                }}>
                {({ validateForm, validateField, resetForm, errors, touched, isValid, isValidating, values, handleChange, setFieldValue, setErrors, setFieldError, initialErrors }) => {
                    const onSelectChange = (value: string, type: string) => {
                        if(Array.isArray(value)) {
                            setFieldValue(type, value.join())
                        } else {
                            setFieldValue(type, value)
                        }
                        formChangedUpdate(true)
                    }
                    const onDateChange = (value: string, type: string) => {
                        setFieldValue(type, value !== null ? moment(value).format("yyyy-MM-DD") : undefined)
                        formChangedUpdate(true)
                    }
                    const confirmReset = () => {
                        setPopupVisible(false)
                        resetForm()
                        formChangedUpdate(false)
                    }
                    const confirmEdit = () => {
                        setPopupEditVisible(false)
                        formChangedUpdate(true)
                        setDisabled(false)
                    }
                    const loginAction = () => {
                        //@ts-ignore
                        let userId = values.login
                        let profileId = userId
                        let pass = ''
                        const {apiURL, adminSuffix, type, env, accountType, admin, routeSuffix} = envs
                        if(envs.admin) {
                            //@ts-ignore
                             dispatch(setEnvs({apiURL, adminSuffix, type, env, accountType, userId, routeSuffix, profileId: '', userPass: values.password, admin}))
                        } else {
                            //@ts-ignore
                            dispatch(setEnvs({apiURL, adminSuffix, type, env, accountType, userId, routeSuffix, profileId, userPass: values.password, admin}))
                        }
                        //setMessageShown(true)
                    }
                    const registerAction = () => {
                        validateForm().then((formErrors) => {
                            if(_.isEmpty(formErrors)) {
                                //@ts-ignore
                                dispatch(userCreate({ data: {type: values.clientType, email: values.email, password: values.password} }))
                            } else {
                                return
                            }
                        })
                        
                    }
                    const basicFormProps = {values: values, errors: errors, handleInputChange: handleInputChange, onSelectChange: onSelectChange, onDateChange: onDateChange, setFieldValue: setFieldValue, disabled, initialErrorsValues: initialErrors, touched: touched}
                    return (
                        <Form className='ant-form-vertical' id={id}>
                            <FormObserver type={type} />
                            <Space direction='vertical' size={'large'}>
                                {children(basicFormProps)}
                            </Space>
                            {errorsListing && finalSubmit &&
                                <div id='errorSummaryUser' className="desc" style={{'marginBottom': '30px'}}>
                                  <Paragraph>
                                    <Text
                                      strong
                                      style={{
                                        fontSize: 16,
                                      }}
                                    >
                                      The content you try to submit has following errors:
                                    </Text>
                                  </Paragraph>
                                      <>
                                        {kycApplication.validation.map(step => (
                                            <>
                                                {step?.validationErrors.length ? (
                                                    <ErrorElement formName={step.link} business={false} showErrors={() => showErrors(step.link)} elem={step.link} visibleErrors={visibleErrors} errorsListing={step} header={<Text>Some <b>{step.name}</b> fields are missing</Text>} route={step.link} additionalAction={() => navBack(step.link)}/>
                                                ) : <></>}
                                            </>
                                        ))}
                                        {directorError &&
                                              <ErrorParagraph>
                                                <CloseCircleOutlined />At least one <b>Director</b> should be defined {formUpdated.updated ? (<CustomLinkSpan onClick={() => navBack(apsLink)} >Fix issue &gt;</CustomLinkSpan>) : (<LinkEl to={isStandaloneAdmin ? envs.routeSuffix + '/' + envs.profileId + apsLink : envs.routeSuffix + apsLink}>Fix issue &gt;</LinkEl> )}
                                              </ErrorParagraph>
                                        }
                                        {beneficialOwnerError &&
                                            <ErrorParagraph>
                                            <CloseCircleOutlined />At least one <b>Beneficial Owner</b> should be defined {formUpdated.updated ? (<CustomLinkSpan onClick={() => navBack(ubosLink)} >Fix issue &gt;</CustomLinkSpan>) : (<LinkEl to={isStandaloneAdmin ? envs.routeSuffix + '/' + envs.profileId + ubosLink : envs.routeSuffix + ubosLink}>Fix issue &gt;</LinkEl> )}
                                            </ErrorParagraph>
                                        }
                                        {signatoryError &&
                                            <ErrorParagraph>
                                            <CloseCircleOutlined />At least one <b>Signatory</b> should be defined
                                            </ErrorParagraph>
                                        }
                                        {envs.admin && (approvalError || approvalDocError) &&
                                            <>
                                                {approvalErrors.length ? approvalErrors.map((step: any) => (
                                                    <>
                                                        {step?.validationErrors.length || step?.docErrors.length  ? (
                                                            <ErrorElement isApproval={true} formName={step.link} business={false} showErrors={() => showErrors(step.link + 'approval')} elem={step.link + 'approval'} visibleErrors={visibleErrors} errorsListing={step} header={<Text>Approvals in <b>{step.name}</b> are missing</Text>} route={step.link} additionalAction={() => navBack(step.link)}/>
                                                        ) : <></>}
                                                    </>
                                                )) :  <></>}
                                            </>
                                        }
                                         {envs.admin && signatoryApprovalError &&
                                           <ErrorParagraph>
                                            <CloseCircleOutlined />Approvals in <b>Signatories</b> are missing
                                           </ErrorParagraph>
                                        }
                                        </>
                                    
                                    </div>
                                }
                            <>
                            </>
                            {(finalSubmit || loginSubmit || registerSubmit || saveDraft) ? (
                                <>
                                {(hideSaving || (finalSubmit && envs.admin)) ? (<></>) : (
                                    <StickyNav id={finalSubmit && envs.accountType === 'PRIVATE' && fixedNavPos === true ? 'formFixed' : ''} style={{display: hideOnDisabled ? formUpdated.updated ? 'block' : 'none' : 'block', bottom: `${envs.admin ? (standaloneFormSubmit ? '60px' : '120px') : (finalSubmit) ? '0' : '69px'}`}} className={finalSubmit ? 'bottom form-nav' : (loginSubmit || registerSubmit) ? 'no-stick form-nav' : 'form-nav'}>
                                        <StickyContent className={nav.nav.step && nav.nav.step > 0 ? 'content kyc-form-nav' : 'content'} >
                                        <Row>
                                            {loginSubmit || registerSubmit ? (
                                                <Col md={24}>
                                                    <ButtonUpper btnType="primary" htmlType="submit" disabled={formDisabled || disabled} onClick={registerSubmit ? () => registerAction() : () => loginAction()} block>{registerSubmit ? 'Register' :  'Login'}</ButtonUpper>
                                                </Col>
                                            ) : (
                                            <>
                                            <Col md={12}>
                                                {finalSubmit ? (
                                                    <>
                                                        <CustomLink onClick={() => navBack(prev)} >
                                                            <ButtonUpper btnType="primary" className='left' ghost>Back</ButtonUpper>
                                                        </CustomLink>
                                                    </>
                                                    
                                                ) : (
                                                    <></>
                                                )}
                                            </Col>
                                            <ColRight>
                                                {deleteForm &&
                                                    <PopconfirmCustom
                                                        title={isUbo ? "Are You sure You want to delete this Ultimate Beneficial Owner?" : "Are You sure You want to delete this Authorized Person?"}
                                                        visible={deletePopupVisible}
                                                        onVisibleChange={handleDeleteVisibleChange}
                                                        onConfirm={() => handleFormDelete()}
                                                        onCancel={cancelDelete}
                                                        okText="Yes, delete"
                                                        cancelText="Cancel"
                                                        getPopupContainer={(trigger) => trigger}
                                                        icon={<QuestionCircleOutlined />}
                                                    >
                                                        <ButtonUpper btnType="primary" className='left' disabled={disabled} ghost>Delete</ButtonUpper>
                                                    </PopconfirmCustom>
                                                }
                                                {/*{restoreForm &&
                                                    <ButtonUpper btnType="primary" className='left' disabled={formChanged ? true : false || disabled} onClick={() => handleFormDelete(true)} ghost>Restore</ButtonUpper>
                                                }*/}
                                                {/*{validateButton &&
                                                    <ButtonUpper btnType="text" className='left' onClick={validateStepAction} disabled={formUpdated.updated}>Validate</ButtonUpper>
                                                }*/}
                                                {saveDraft &&
                                                    <ButtonUpper btnType="primary" htmlType="submit" className='left' disabled={formUpdated.updated ? false : true}>Save draft</ButtonUpper>
                                                }
                                                {finalSubmit ? (
                                                    <ButtonUpper className='submit-floating' btnType="primary" htmlType="submit" disabled={disabled} ghost onClick={() => finalSubmitAction()}>Submit to us</ButtonUpper>
                                                ) : (
                                                    <>
                                                        {!saveDraft && <ButtonUpper className='submit-floating' btnType="primary" htmlType="submit" disabled={formDisabled || (formUpdated.updated ? false : true)} >{saveText ? saveText : <>{'Save'}</>}</ButtonUpper>}
                                                    </>
                                                )}
                                            </ColRight>
                                            </>
                                            )}
                                        </Row>
                                        </StickyContent>
                                    </StickyNav>    
                                )}
                                </>
                            ) : (<></>)}
                        </Form>
                    )}}
                </Formik>
        </>
             
    )
}

export default BasicFormWrapper
