import { createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../../api/kyc/api";
import { AsyncThunkActionType } from "../../store";
import { AsyncThunkDataType } from "../../common";
import { ArchiveReponse } from "../slices/ubosSlice";
import { Components, Paths } from "../../../api/kyc/generated/client";

type ArchiveAPRequest = Components.Schemas.ArchiveRequest;
type PathParameters = Paths.ArchiveBeneficialOwner.PathParameters;

export const uboArchive: AsyncThunkActionType<
  ArchiveReponse,
  AsyncThunkDataType<PathParameters, ArchiveAPRequest, "data">
> = createAsyncThunk(
  "uboArchive",
  async ({ params, data, config }, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      const response = await api.ArchiveBeneficialOwner(params, data, config);
      return response.data as ArchiveReponse;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);
